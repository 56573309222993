import * as colors from '@material-ui/core/colors';

export function scrollToElm(container, elm, duration = 2) {
  var pos = getRelativePos(elm);
  scrollTo( container, pos.top , duration);  // duration in seconds
}

export function getRelativePos(elm) {
  var pPos = elm.parentNode.getBoundingClientRect(), // parent pos
      cPos = elm.getBoundingClientRect(), // target pos
      pos = {};

  pos.top    = cPos.top    - pPos.top + elm.parentNode.scrollTop;
  pos.right  = cPos.right  - pPos.right;
  pos.bottom = cPos.bottom - pPos.bottom;
  pos.left   = cPos.left   - pPos.left;

  return pos;
}
    
export function scrollTo(element, to, duration, onDone) {
    var start = element.scrollTop,
        change = to - start,
        startTime = performance.now(),
        now, elapsed, t;

    function animateScroll(){
        now = performance.now();
        elapsed = (now - startTime)/1000;
        t = (elapsed/duration);

        element.scrollTop = start + change * easeInOutQuad(t);

        if( t < 1 )
          window.requestAnimationFrame(animateScroll);
        else
          onDone && onDone();
    };

    animateScroll();
}

export function getDimentions(element) {
  if (element) {
    const boundingRect = element.getBoundingClientRect();
    const { width, height } = boundingRect;
    return { width: Math.round(width), height: Math.round(height) };
  }
  return { width: 0, height: 0};
}

function easeInOutQuad(t){ return t<.5 ? 2*t*t : -1+(4-2*t)*t };

export function getColor({color = 'black', shade = 500}) {
  if (color === 'black') {
    return color;
  }
  return colors[color][shade];
}

export function getSVGPin(color) {
  var svgBlob = new Blob(
    [
      `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">`,
      `<path fill="${color}" d="M256,0C153.755,0,70.573,83.182,70.573,185.426c0,126.888,165.939,313.167,173.004,321.035
			c6.636,7.391,18.222,7.378,24.846,0c7.065-7.868,173.004-194.147,173.004-321.035C441.425,83.182,358.244,0,256,0z M256,278.719
			c-51.442,0-93.292-41.851-93.292-93.293S204.559,92.134,256,92.134s93.291,41.851,93.291,93.293S307.441,278.719,256,278.719z"/>`,
      `</svg>`,
    ],
    { type: "image/svg+xml" }
  );

  var svgUrl = URL.createObjectURL(svgBlob);
  
  return svgUrl;
}