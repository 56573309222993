import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import * as Actions from "actions";
import { useCentrifuge } from "contexts";
import { setFavicon } from "helpers";
import React, { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import * as Routes from "routes";
import theme from "themes/theme";
import {
  REACT_APP_API,
  REACT_APP_CLIENT_COLOR,
  REACT_APP_CLIENT_EMAIL,
  REACT_APP_CLIENT_NAME,
  REACT_APP_CLIENT_WEBSITE,
  REACT_APP_FAVICON,
} from "../../../config";
import { AREA_CHANNEL, routeNames } from "../../../constants";
import WrapperRootPage from "../component/WrapperRootPage";

export default function Root() {
  const dispatch = useDispatch();

  const storedArea = useSelector((state) => state.storedArea);

  const [currentAreaSubscription, setCurrentAreaSubscription] = useState(null);

  const centrifugeContext = useCentrifuge();

  useIdleTimer({
    timeout: 1000 * 60 * 5,
    onIdle: () => centrifugeContext.disconnect(),
    onActive: () => centrifugeContext.connect(),
    debounce: 250,
  });

  const props = useSelector((state) => state);

  useEffect(() => {
    if (
      !centrifugeContext.isConnected &&
      centrifugeContext.initialized &&
      currentAreaSubscription !== null
    ) {
      setCurrentAreaSubscription(null);
    }
    if (
      centrifugeContext.isConnected &&
      (!currentAreaSubscription ||
        storedArea.toString() !== currentAreaSubscription.split("_")[2])
    ) {
      setCurrentAreaSubscription((current) => {
        if (current) {
          centrifugeContext.unsubscribe(current);
        }
        const newArea = `chat:assignment_area_${storedArea}`;
        centrifugeContext.subscribe(newArea, (message) => {
          message.currentUser = props.user;
          dispatch(Actions.newMessage(AREA_CHANNEL, message));
        });
        return newArea;
      });
    }
  }, [
    dispatch,
    storedArea,
    centrifugeContext,
    currentAreaSubscription,
    props.user,
  ]);

  const muiTheme = createMuiTheme({
    palette: { ...theme, type: props.app.darkMode ? "dark" : "light" },
    typography: {
      useNextVariants: true,
    },
  });

  function getRoutes() {
    const { user } = props;
    const routes = [];

    for (const k in routeNames) {
      if (routeNames.hasOwnProperty(k)) {
        const route = Routes[routeNames[k]](user ? user.services : {});
        if (route.onEnter()) {
          routes.unshift(route);
        }
      }
    }

    return routes;
  }

  function getAllRoutes() {
    const { user } = props;
    const routes = [];

    for (const k in routeNames) {
      if (routeNames.hasOwnProperty(k)) {
        const route = Routes[routeNames[k]](user ? user.services : {});
        routes.unshift(route);
      }
    }

    return routes;
  }

  function init() {
    console.log(
      `%cDeveloped by ${REACT_APP_CLIENT_NAME}`,
      `color: ${REACT_APP_CLIENT_COLOR}; font-size: 28px`
    );
    console.log(
      "%cin Los Angeles, California",
      "color: #607d8b; font-size: 18px"
    );
    console.log(
      `%c${REACT_APP_CLIENT_EMAIL}`,
      "color: #607d8b; font-size: 12px"
    );
    console.log(
      `%c${REACT_APP_CLIENT_WEBSITE}`,
      "color: #607d8b; font-size: 12px"
    );
    console.log(
      `%cRunning on ${REACT_APP_API} API`,
      "color: #607d8b; font-size: 12px"
    );
    setFavicon(REACT_APP_FAVICON);
    return Promise.all([
      dispatch(Actions.getUserApps()),
      dispatch(Actions.getAreas()),
    ]);
  }

  return (
    <MuiThemeProvider theme={muiTheme}>
      <Router>
        <WrapperRootPage
          stopImpersonate={(...args) =>
            dispatch(Actions.stopImpersonate(...args))
          }
          setDarkMode={(...args) => dispatch(Actions.setDarkMode(...args))}
          app={props.app}
          checkAccess={(...args) => dispatch(Actions.checkAccess(...args))}
          getCurrentUser={(...args) =>
            dispatch(Actions.getCurrentUser(...args))
          }
          storeResetPasswordToken={(...args) =>
            dispatch(Actions.storeResetPasswordToken(...args))
          }
          signout={(...args) => dispatch(Actions.signout(...args))}
          requestResetPassword={(...args) =>
            dispatch(Actions.requestResetPassword(...args))
          }
          resetPassword={(...args) => dispatch(Actions.resetPassword(...args))}
          validateEmail={(...args) => dispatch(Actions.validateEmail(...args))}
          signup={(...args) => dispatch(Actions.signup(...args))}
          user={props.user}
          userApps={props.userApps}
          errors={props.errors}
          routes={getRoutes()}
          allRoutes={getAllRoutes()}
          init={init}
        />
      </Router>
    </MuiThemeProvider>
  );
}
