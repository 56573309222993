import { Button } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import Snackbar from "@material-ui/core/Snackbar";
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import InsertEmoticonIcon from "@material-ui/icons/InsertEmoticon";
import { Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";
import { scrollToElm } from "helpers";
import BlackChip from "hooks/BlackChip";
import ColoredTextTypography from "hooks/ColoredTextTypography";
import MessageBubble from "hooks/MessageBubble";
import RoundedTextField from "hooks/RoundedTextField";
import moment from "moment";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function MessageThread({
  messages,
  onCreateMessage,
  scrollableHeight,
  onResolve,
  resolved,
  ...other
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const [messagesData, setMessagesData] = useState([]);
  const [messagesQuantity, setMessagesQuantity] = useState({
    current: messages.length,
    new: 0,
  });
  const [showScrollToBottom, setShowScrollToBottom] = useState(false);
  const classes = useStyles();

  const messagesEnd = useRef(null);
  const messageContainer = useRef(null);
  const first = useRef(false);

  const { uuid } = useSelector((state) => state.user);

  const addEmoji = (emoji) => {
    console.log(emoji);
    setNewMessage((current) => `${current}${emoji.native}`);
  };

  const prepareMessageData = useCallback(
    (messages) => {
      const list = messages
        .map((m) => {
          m.createdAt = new Date(m.createdAt);
          m.variant = m.senderUUID === uuid ? "sender" : "receiver";
          return m;
        })
        .sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime());

      if (list.length) {
        const groups = {};
        let count = 0;
        let lastSender = list[0].senderUUID;

        for (let index = 0; index < list.length; index++) {
          const element = list[index];
          const lastElement = list[index - 1];

          if (lastElement) {
            if (
              moment(element.createdAt).diff(lastElement.createdAt, "minutes") >
              30
            ) {
              lastElement.displayTime = element.createdAt;
            }
          }

          if (lastSender !== element.senderUUID) {
            count += 1;
          }

          if (!groups[count]) {
            lastSender = element.senderUUID;
            groups[count] = {
              id: `group_${count}`,
              variant: element.variant,
              name: element.displayName,
              messages: [],
            };
          }
          groups[count].messages.push(element);
        }

        setMessagesQuantity((mq) => {
          let newVal = list.length - mq.current;
          if (newVal > 0) {
            if (list[list.length - 1].variant !== "sender") {
              if (
                messageContainer.current.scrollHeight >
                messageContainer.current.clientHeight
              ) {
                setShowScrollToBottom(true);
              } else {
                newVal = 0;
              }
            } else {
              newVal = 0;
              scrollToBottom();
            }
          }
          return { current: list.length, new: mq.new + newVal };
        });
        setMessagesData(Object.values(groups));
      }
    },
    [uuid]
  );

  useEffect(() => {
    prepareMessageData(messages);
    setNewMessage("");
  }, [messages, prepareMessageData]);

  useEffect(() => {
    if (!showScrollToBottom) {
      setMessagesQuantity((mq) => ({ ...mq, new: 0 }));
    }
  }, [showScrollToBottom]);

  useEffect(() => {
    if (messagesData.length && !first.current) {
      messageContainer.current.scrollTop =
        messageContainer.current.scrollHeight -
        messageContainer.current.offsetHeight;
      first.current = true;
    }
  }, [messagesData]);

  const handleClick = (event) => {
    console.log("click");
    setAnchorEl(event.currentTarget);
  };

  const scrollToBottom = () => {
    scrollToElm(messageContainer.current, messagesEnd.current, 1);
  };

  const handleScroll = () => {
    const currentScrolledValue =
      messageContainer.current.scrollHeight -
      messageContainer.current.scrollTop;
    setShowScrollToBottom(
      currentScrolledValue - messageContainer.current.offsetHeight > 50
    );
  };

  const scrollableProps = {
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    height: scrollableHeight || "calc(100vh - 177px)",
    p: 2,
  };

  console.log(resolved);

  return (
    <Box className={classes.content}>
      <Box display="flex" flexDirection="column" position="relative">
        <Box
          {...scrollableProps}
          onScroll={handleScroll}
          ref={messageContainer}
        >
          {messagesData.length ? (
            <Button
              fullWidth
              style={{
                background: resolved ? "#34BB1F" : "#f44336",
                color: "white",
                textTransform: "none",
                marginBottom: 16,
                position: "sticky",
                top: 0,
              }}
              onClick={onResolve}
            >
              {resolved
                ? "This conversation has been resolved"
                : "Mark this conversation as resolved"}
            </Button>
          ) : (
            []
          )}
          {messagesData.map((md) => {
            return (
              <Box key={md.id} display="flex" flexDirection="column">
                {md.variant === "receiver" && (
                  <ColoredTextTypography
                    key={`displayName_${md.id}`}
                    color="grey"
                    shade="600"
                    className={classes.displayName}
                  >
                    {md.name}
                  </ColoredTextTypography>
                )}
                {md.messages.map((m, index) => (
                  <Fragment key={`content_${m.messageUUID}`}>
                    <MessageBubble
                      message={m}
                      variant={m.variant}
                      date={m.createdAt}
                      last={md.messages.length - 1 === index}
                    >
                      {m.content}
                    </MessageBubble>
                    {m.displayTime ? (
                      <ColoredTextTypography
                        color="grey"
                        shade="600"
                        align="center"
                        className={classes.displayTime}
                      >
                        <span>{moment(m.displayTime).calendar()}</span>
                      </ColoredTextTypography>
                    ) : null}
                  </Fragment>
                ))}
              </Box>
            );
          })}

          <div ref={messagesEnd}></div>
        </Box>

        <Snackbar open={showScrollToBottom} className={classes.snackbar}>
          <Badge
            color="error"
            badgeContent={messagesQuantity.new}
            classes={{ badge: classes.badge }}
          >
            <BlackChip
              label="Scroll to bottom"
              onClick={scrollToBottom}
              className={classes.chip}
            />
          </Badge>
        </Snackbar>
      </Box>

      <Box
        className={classes.messageInputContainer}
        display="flex"
        alignItems="center"
        px={1}
      >
        <IconButton aria-label="Insert emoticon" onClick={handleClick}>
          <InsertEmoticonIcon />
        </IconButton>
        <RoundedTextField
          size="small"
          variant="outlined"
          placeholder="Message"
          color="white"
          fullWidth
          bordered
          multiline
          value={newMessage}
          rows={1}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              if (!newMessage) {
                return e.preventDefault();
              }
              if (!e.shiftKey) {
                return onCreateMessage && onCreateMessage(newMessage);
              }
            }
          }}
        />
      </Box>

      <Popover
        id="emoji-popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Picker
          onSelect={addEmoji}
          showPreview={false}
          showSkinTones={false}
          emojiTooltip
        />
      </Popover>
    </Box>
  );
}
