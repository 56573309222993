import green from "@material-ui/core/colors/green";

const bc = "black";

export default (theme) => ({
  root: {
    "&:first-child": {
      marginTop: theme.spacing(2),
    },
    borderRadius: 8,
    cursor: "pointer",
    background: (props) => {
      var stripColor = "rgba(155, 155, 155, 0.15)";
      if (props.active) stripColor = "rgba(33, 150, 243, 0.15)";
      if (props.skipped) {
        return `repeating-linear-gradient(
  -45deg,
  transparent,
  transparent 10px,
  ${stripColor} 10px,
  ${stripColor} 20px
)`;
      }

      if (props.active && !props.completed) return "rgba(33, 150, 243, 0.15)";
      if (props.active && props.completed) return "rgba(76,175,80, 0.15)";
      return "transparent";
    },
    position: "relative",
  },
  timeLine: {
    marginBottom: 0,
    paddingBottom: 0,
    marginTop: -10,
  },
  item: {
    minHeight: 30,
  },
  dot: {
    marginBottom: 0,
    marginTop: 4,
    padding: (props) => (props.completed ? 0 : 7),
    backgroundColor: (props) => (props.completed ? green[500] : bc),
  },
  dotIcon: {
    fontSize: 14,
  },
  line: {
    backgroundColor: (props) => (props.completed ? green[500] : bc),
  },
  chip: {
    minWidth: 40,
  },
  content: {
    paddingTop: 0,
    minHeight: (props) => (props.skipped ? 140 : undefined),
    paddingLeft: 8,
  },
  stopID: {
    position: "absolute",
    top: 40,
    lineHeight: 1,
    textAlign: "center",
  },
  skipped: {
    position: "absolute",
    top: "calc(50% + 20px)",
    left: 6,
    fontWeight: 600,
    lineHeight: 1,
    textAlign: "center",
    transform: "rotate(-90deg)",
  },
  truck: {
    position: "absolute",
    top: 0,
    left: "8",
  },
  more: {
    top: 4,
    right: 4,
    position: "absolute",
    zIndex: 100,
  },
  skip: {
    background: theme.palette.background.paper,
    display: "inline-block",
    padding: 6,
    border: "solid 1px rgba(155,155,155,0.3)",
    marginTop: 4,
    borderRadius: 4,
  },
});
