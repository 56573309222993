import { getKey, handleError, handleLoading, handleSuccess } from "helpers";
import {
  CLEAN_STATE,
  CREATE_STOP,
  CREATE_TASK,
  GET_ASSIGNMENTS,
  GET_ASSIGNMENT_BY_ID,
  GET_GPS_UPDATE_BY_ASSIGNMENT_ID,
  GET_MESSAGES_BY_ASSIGNMENT_ID,
  REQUEST_INITIAL_STATE,
  SEARCH_LOCATION,
  SET_SEARCH_DRIVER,
  UPDATE_ASSIGNMENT,
} from "../constants";

const initialState = {
  search: "",
  [GET_ASSIGNMENTS]: REQUEST_INITIAL_STATE,
};

export default (state = initialState, { type, payload }) => {
  const cleanableStates = [
    GET_ASSIGNMENTS,
    UPDATE_ASSIGNMENT,
    GET_ASSIGNMENT_BY_ID,
    GET_MESSAGES_BY_ASSIGNMENT_ID,
    CREATE_STOP,
    CREATE_TASK,
  ];
  console.log("this is the state");
  console.log(state);

  switch (type) {
    case SET_SEARCH_DRIVER:
      return { ...state, search: payload };

    case `${GET_ASSIGNMENTS}_LOADING`:
      return handleLoading({ state, action: GET_ASSIGNMENTS });
    case `${GET_ASSIGNMENTS}_SUCCESS`:
      const newMessages = getKey("newMessages", {});
      const assignments = payload.map((assignment) => {
        assignment.hasNewMessage = false;
        if (newMessages[assignment.id]) {
          assignment.hasNewMessage = newMessages[assignment.id];
        }
        if (assignment.issues && assignment.issues.length) {
          const a = Object.assign(assignment.issues[0]);
          a.dismissed = false;
          assignment.issues = assignment.issues.filter((i) => !i.dismissed);
          assignment.issues.push(a);
        }
        return assignment;
      });
      return handleSuccess({
        state,
        action: GET_ASSIGNMENTS,
        payload: assignments,
        options: { sortBy: "userName" },
      });
    case `${GET_ASSIGNMENTS}_ERROR`:
      return handleError({ state, action: GET_ASSIGNMENTS, payload });

    case `${GET_ASSIGNMENT_BY_ID}_LOADING`:
      return handleLoading({ state, action: GET_ASSIGNMENT_BY_ID });
    case `${GET_ASSIGNMENT_BY_ID}_SUCCESS`:
      payload.jobs = payload.jobs.map((job) => {
        job.stops = (job.stops || []).map((stop) => {
          stop.tasks = stop.tasks.map((task) => {
            const parents = task.interactors
              .filter((i) => !i.parentKey)
              .reduce((acc, interactor) => {
                if (!acc[interactor.key]) {
                  acc[interactor.key] = interactor.state.value;
                }
                return acc;
              }, {});

            task.interactors = task.interactors.map((interactor) => {
              const parent = parents[interactor.parentKey];
              interactor.completed =
                interactor.interactions.length > 0 ||
                (parent && parseInt(parent) === 0);
              return interactor;
            });

            if (
              task.interactors.length &&
              (task.kind === "startDay" ||
                task.kind === "arrive" ||
                task.kind === "depart")
            ) {
              const interactor = task.interactors[0];
              if (interactor && interactor.interactions.length) {
                task.time = interactor.interactions[0].createdAt;
              }
            }

            task.completed = task.interactors.every(
              (interactor) => interactor.completed
            );
            return task;
          });
          stop.totalTasks = stop.tasks.length;
          stop.totalCompletedTasks = stop.tasks.filter(
            (task) => task.completed
          ).length;
          stop.totalUncompletedTasks =
            stop.totalTasks - stop.totalCompletedTasks;
          stop.atLeastOneTaskComplete = stop.totalCompletedTasks > 0;
          stop.completed = stop.totalTasks === stop.totalCompletedTasks;

          stop.currentTask = null;
          const firstUncompleted = stop.tasks.find((task) => !task.completed);
          if (firstUncompleted) {
            stop.currentTask = firstUncompleted;
          }
          return {
            ...stop,
            lat: parseFloat(stop.location.latitude),
            lng: parseFloat(stop.location.longitude),
          };
        });
        job.currentStop = -1;
        const firstUncompleted = job.stops.find((stop) => !stop.completed);
        if (firstUncompleted) {
          job.currentStopOrder = firstUncompleted.stopOrder;
        }
        return job;
      });
      payload.stops = payload.jobs.map((job) => job.stops || []).flat();
      payload.mapStops = payload.jobs
        .map((job) => job.stops.filter((stop) => !stop.completed) || [])
        .flat();

      // payload.issues = [
      //   {id: 1, name: 'testing issue 1', createdAt: "2021-08-19T02:04:47Z", dismissed: false},
      //   {id: 2, name: 'testing issue 2', createdAt: "2021-08-19T02:04:47Z", dismissed: false},
      //   {id: 3, name: 'testing issue 3', createdAt: "2021-08-19T02:04:47Z", dismissed: false}
      // ];

      return handleSuccess({ state, action: GET_ASSIGNMENT_BY_ID, payload });
    case `${GET_ASSIGNMENT_BY_ID}_ERROR`:
      return handleError({ state, action: GET_ASSIGNMENT_BY_ID, payload });

    case `${UPDATE_ASSIGNMENT}_LOADING`:
      return handleLoading({ state, action: UPDATE_ASSIGNMENT });
    case `${UPDATE_ASSIGNMENT}_SUCCESS`:
      return handleSuccess({ state, action: UPDATE_ASSIGNMENT, payload });
    case `${UPDATE_ASSIGNMENT}_ERROR`:
      return handleError({ state, action: UPDATE_ASSIGNMENT, payload });

    case `${GET_MESSAGES_BY_ASSIGNMENT_ID}_LOADING`:
      return handleLoading({ state, action: GET_MESSAGES_BY_ASSIGNMENT_ID });
    case `${GET_MESSAGES_BY_ASSIGNMENT_ID}_SUCCESS`:
      return handleSuccess({
        state,
        action: GET_MESSAGES_BY_ASSIGNMENT_ID,
        payload,
      });
    case `${GET_MESSAGES_BY_ASSIGNMENT_ID}_ERROR`:
      return handleError({
        state,
        action: GET_MESSAGES_BY_ASSIGNMENT_ID,
        payload,
      });

    case `${SEARCH_LOCATION}_LOADING`:
      return handleLoading({ state, action: SEARCH_LOCATION });
    case `${SEARCH_LOCATION}_SUCCESS`:
      return handleSuccess({ state, action: SEARCH_LOCATION, payload });
    case `${SEARCH_LOCATION}_ERROR`:
      return handleError({ state, action: SEARCH_LOCATION, payload });

    case `${CREATE_STOP}_LOADING`:
      return handleLoading({ state, action: CREATE_STOP });
    case `${CREATE_STOP}_SUCCESS`:
      return handleSuccess({ state, action: CREATE_STOP, payload });
    case `${CREATE_STOP}_ERROR`:
      return handleError({ state, action: CREATE_STOP, payload });

    case `${CREATE_TASK}_LOADING`:
      return handleLoading({ state, action: CREATE_TASK });
    case `${CREATE_TASK}_SUCCESS`:
      return handleSuccess({ state, action: CREATE_TASK, payload });
    case `${CREATE_TASK}_ERROR`:
      return handleError({ state, action: CREATE_TASK, payload });

    case `${GET_GPS_UPDATE_BY_ASSIGNMENT_ID}_LOADING`:
      return handleLoading({ state, action: GET_GPS_UPDATE_BY_ASSIGNMENT_ID });
    case `${GET_GPS_UPDATE_BY_ASSIGNMENT_ID}_SUCCESS`:
      return handleSuccess({
        state,
        action: GET_GPS_UPDATE_BY_ASSIGNMENT_ID,
        payload: payload.map((gpsUpdate) => ({
          ...gpsUpdate,
          name: gpsUpdate.deviceName,
          lat: parseFloat(gpsUpdate.latitude),
          lng: parseFloat(gpsUpdate.longitude),
          time: new Date(gpsUpdate.timestamp).getTime(),
        })),
        options: { sortBy: "time", type: "numeric" },
      });
    case `${GET_GPS_UPDATE_BY_ASSIGNMENT_ID}_ERROR`:
      return handleError({
        state,
        action: GET_GPS_UPDATE_BY_ASSIGNMENT_ID,
        payload,
      });

    case CLEAN_STATE:
      if (cleanableStates.includes(payload.state)) {
        return {
          ...state,
          [payload.state]: REQUEST_INITIAL_STATE,
        };
      }
      return state;

    default:
      return state;
  }
};
