import {
  SET_CURRENT_WORKSPACE,
  SET_DARK_MODE,
  SET_STORED_AREA,
  STORE_RESET_PASSWORD_TOKEN,
} from "constants/app";

export const storeResetPasswordToken = (token) => async (dispatch) =>
  dispatch({
    type: STORE_RESET_PASSWORD_TOKEN,
    token,
  });

export const setDarkMode = (token) => async (dispatch) =>
  dispatch({
    type: SET_DARK_MODE,
    token,
  });

export const setCurrentWorkspace = (token) => async (dispatch) =>
  dispatch({
    type: SET_CURRENT_WORKSPACE,
    token,
  });

export const setStoredArea = (storedArea) => async (dispatch) =>
  dispatch({
    type: SET_STORED_AREA,
    storedArea,
  });
