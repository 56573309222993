import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core";
// import PropTypes from 'prop-types';
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import React from "react";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function GPSUpdateList({ gpsUpdates }) {
  // const [ state, setState ] = React.useState(initialValue);
  const classes = useStyles(styles);

  console.log(gpsUpdates);

  return (
    <List style={{ height: "calc(100vh - 130px)", overflow: "scroll" }}>
      {gpsUpdates
        .sort((a, b) => b.id - a.id)
        .map((g) => (
          <ListItem>
            <ListItemAvatar>
              <Typography variant="caption">{`#${g.id}`}</Typography>
            </ListItemAvatar>
            <ListItemText
              primary={`${g.deviceName} - v${g.deviceVersion}`}
              secondary={moment(g.createdAt).format("lll")}
            />
            <ListItemSecondaryAction>
              <Typography variant="caption">{`${g.lat};${g.lng}`}</Typography>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
    </List>
  );
}
