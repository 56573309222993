import grey from '@material-ui/core/colors/grey';
import green from '@material-ui/core/colors/green';

const bc = 'black';

export default theme => ({
  chip: {
    color: 'white',
    backgroundColor: props => props.completed ? green[500] : bc,
    '&:hover, &:focus': {
      backgroundColor: props => props.completed ? green[600] : grey[800],
    },
    '&:active': {
      backgroundColor: props => props.completed ? green[600] : grey[800],
    }
  }
});
