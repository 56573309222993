import { networkAction } from 'helpers/network/networkAction';
import { uuidV4 } from 'helpers';

import {
  GET_MESSAGES_BY_ASSIGNMENT_ID,
  CREATE_MESSAGE
} from '../constants';

import * as Api from 'api';

export const getMessagesByAssignmentId = (id) => async dispatch => networkAction(
  dispatch,
  GET_MESSAGES_BY_ASSIGNMENT_ID,
  Api.getMessagesByAssignmentId,
  [id]
);

export const createMessage = (content, assignmentID, currentUser) => async dispatch => networkAction(
  dispatch,
  CREATE_MESSAGE,
  Api.createMessage,
  [content, parseInt(assignmentID, 10), uuidV4(), currentUser]
);