import { TRIPS } from 'constants/routes';
import { serviceRoles } from 'config/access';
import { checkAccess } from 'helpers/auth';
import TripsPage from './container/TripsPage';

export default function getSettingsRoute(userAuth) {
  const { account } = serviceRoles;
  const requireAuth = {
    [account.name]: [
      account.roles.any,
    ],
  };
  return {
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    path: TRIPS,
    withSidebar: true,
    withAppBar: true,
    component: TripsPage,
  };
}
