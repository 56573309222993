// routes
import LinearScale from '@material-ui/icons/LinearScaleTwoTone';
import LocalShippingIcon from '@material-ui/icons/LocalShippingTwoTone';
import {
  TRIPS,
  WORKFLOW
} from 'constants/routes';


export const menu = [
  {
    icon: LinearScale,
    label: 'Trip Reports',
    path: TRIPS,
    divider: true,
  },
  {
    icon: LocalShippingIcon,
    label: 'Workflow',
    path: WORKFLOW,
    divider: true,
  },
];
