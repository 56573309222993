import green from "@material-ui/core/colors/green";
import grey from "@material-ui/core/colors/grey";

export default (theme) => ({
  content: {
    width: "55%",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  },
  iconButton: {
    padding: 7,
  },
  icon: {
    verticalAlign: "middle",
    color: grey[800],
  },
  greenIcon: {
    color: green[500],
  },
  popoverContainer: {
    minWidth: 300,
  },
  anyOrderControl: {
    marginLeft: 0,
    marginRight: 0,
  },
});
