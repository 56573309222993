import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';

// material-ui
import { withStyles } from '@material-ui/core/styles';

// styles
import styles from './styles';

class DatePick extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.any,
    label: PropTypes.string,
  };

  render() {
    const { 
      onChange,
      label,
      value,
    } = this.props;

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          variant="outlined"
          format={'MM/dd/yyyy'}
          style={{ marginTop: 0 }}
          placeholder={'Select a Date'}
          allowKeyboardControl
          margin="normal"
          label={label}
          autoOk
          value={value}
          fullWidth
          onChange={onChange}
          InputLabelProps={{
            shrink: true,
          }}
          renderDay={this.renderDay}
        />
      </MuiPickersUtilsProvider>
    );
  }
}

export default withStyles(styles)(DatePick);
