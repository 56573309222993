import grey from '@material-ui/core/colors/grey';
import blue from '@material-ui/core/colors/blue';

export default theme => ({
  container: {
    maxWidth: '60%',
    marginTop: 0,
    marginBottom: props => theme.spacing(props.last || props.gutterBottom ? 2 : 1),
    position: 'relative',
    borderRadius: 25,
    padding: '10px 20px',

    color: props => props.variant === "sender" ? 'white' : 'black',
    background: props => props.variant === "sender" ? blue[500] : grey[300],
    alignSelf: props => props.variant === "sender" ? 'flex-end' : 'flex-start',

    '&:before, &:after': {
      content: '""',
      width: props => props.last ? undefined : 0,
      position: 'absolute',
      bottom: 0,
      height: 25
    },

    '&:before': {
      right: props => props.variant === "sender" ? -7 : undefined,
      left: props => props.variant === "receiver" ? -7 : undefined,
      width: 20,
      backgroundColor: props => props.variant === "sender" ? blue[500] : grey[300],
      borderBottomLeftRadius: props => props.variant === "sender" ? '16px 14px' : undefined,
      borderBottomRightRadius: props => props.variant === "receiver" ? '16px 14px' : undefined
    },
    
    '&:after': {
      right: props => props.variant === "sender" ? -7 : undefined,
      left: props => props.variant === "receiver" ? -7 : undefined,
      width: 7,
      backgroundColor: 'white',
      borderBottomLeftRadius: props => props.variant === "sender" ? 10 : undefined,
      borderBottomRightRadius: props => props.variant === "receiver" ? 10 : undefined
    }
  },
  message: {
    overflow: 'hidden',
    lineHeight: '24px',
    whiteSpace: 'pre-line',
    display: props => props.expanded ? 'block' : '-webkit-box',
    '-webkit-line-clamp': 10,
    '-webkit-box-orient': 'vertical'
  },
  newMessageTrackingIcon: {
    position: 'absolute',
    bottom: -10,
    left: -10,
    color: grey[500],
    width: '0.8em',
    height: '0.8em'
  }
});
