import React, { Component } from 'react';
import PropTypes from 'prop-types';

// material-ui
import withStyles from '@material-ui/core/styles/withStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContentText from '@material-ui/core/DialogContentText';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Check';

// styles
import styles from './styles';

class FormWorkspace extends Component {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,

    close: PropTypes.func,
    open: PropTypes.bool,

    workspace: PropTypes.object,

    createWorkspace: PropTypes.func,
    updateWorkspace: PropTypes.func,
    deleteWorkspace: PropTypes.func,
    refresh: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      name: '',
      description: '',
      dflt: false,
      pblc: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.init(nextProps.workspace);
    }
  }

  handleCheckboxChange = name => (event) => {
    const { target } = event;
    const { checked } = target;

    this.setState({
      [name]: checked,
    });
  };

  handleChange = name => (event) => {
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  init(workspace) {
    this.setState({
      loading: false,
      name: workspace ? workspace.name : '',
      description: workspace ? workspace.description : '',
      dflt: workspace ? workspace.default : false,
      pblc: workspace ? workspace.public : false,
    });
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: 'Are you sure ?',
        subtitle: 'If you delete this workspace, you won\'t be able to recover it.',
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: 'Cancel',
          level: 'default',
          callback: () => console.log('Callback'),
        },
        confirm: {
          label: 'I am sure',
          level: 'error',
          callback: this.confirmDelete.bind(this),
        },
      },
    );
  }

  async confirmDelete() {
    const {
      deleteWorkspace,
      close,
      refresh,
      workspace,
    } = this.props;

    const { NotificationCenter } = this.context;
    close();
    const resp = await deleteWorkspace(workspace.id);
    if (resp.success) {
      NotificationCenter.sweetAlert(
        {
          title: 'Workspace has been deleted.',
          success: true,
          timestamp: new Date().getTime(),
        },
      );
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async save() {
    const {
      updateWorkspace,
      createWorkspace,
      workspace,
      refresh,
      close,
    } = this.props;

    const {
      name,
      description,
      pblc,
    } = this.state;

    const {
      NotificationCenter,
    } = this.context;

    let resp;
    this.setState({ loading: true });
    if (workspace) {
      resp = await updateWorkspace(
        workspace.id,
        {
          name,
          description,
          public: pblc,
        },
      );
    } else {
      resp = await createWorkspace({
        name,
        description,
        public: pblc,
      });
    }

    this.setState({ loading: false });

    if (resp.success) {
      NotificationCenter.sweetAlert(
        {
          title: workspace ? 'Workspace has been updated.' : 'Workspace has been created.',
          success: true,
          timestamp: new Date().getTime(),
        },
      );

      close();
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  render() {
    const {
      classes,
      fullScreen,
      open,
      workspace,
      close,
    } = this.props;

    const {
      name,
      loading,
      description,
      pblc,
    } = this.state;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        scroll="paper"
        onClose={close}
        aria-labelledby="responsive-dialog-title"
      >
        {
          loading
            ? (
              <div className={classes.progressContainer}>
                <DialogTitle id="alert-dialog-title">
                  Loading
                </DialogTitle>
                <CircularProgress />
              </div>
            )
            : (
              <div>
                <DialogTitle id="responsive-dialog-title">
                  {
                    workspace
                      ? `${workspace.name}`
                      : 'Let\'s Create a New Workspace'
                  }
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {
                      workspace
                        ? 'To update the workspace, fill the form bellow :'
                        : 'To create a new workspace, fill the form bellow :'
                    }
                  </DialogContentText>
                  <Grid container spacing={24}>
                    <Grid item xs={12}>
                      <TextField
                        id="name"
                        label="Name *"
                        className={classes.textField}
                        value={name}
                        onChange={this.handleChange('name')}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="description"
                        label="Description *"
                        className={classes.textField}
                        value={description}
                        onChange={this.handleChange('description')}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={(
                          <Checkbox
                            checked={pblc}
                            onChange={this.handleCheckboxChange('pblc')}
                            value="public"
                            color="primary"
                          />
                        )}
                        label="Public"
                      />
                    </Grid>
                    {/* {
                      workspace
                      && (
                        <Grid item xs={12}>
                          <FormControlLabel
                            control={(
                              <Checkbox
                                checked={dflt}
                                onChange={this.handleCheckboxChange('dflt')}
                                value="dflt"
                                color="primary"
                              />
                            )}
                            label="Default Workspace"
                          />
                        </Grid>
                      )
                    } */}
                  </Grid>
                </DialogContent>
                <DialogActions
                  className={classes.dialogActions}
                >
                  {
                    workspace
                    && (
                      <Button
                        className={classes.delete}
                        onClick={this.delete.bind(this)}
                      >
                        <DeleteIcon className={classes.leftIcon} />
                        &nbsp;Delete
                      </Button>
                    )
                  }
                  <Button
                    onClick={close}
                  >
                    <CloseIcon className={classes.leftIcon} />
                    &nbsp;Close
                  </Button>
                  <Button
                    color="primary"
                    autoFocus
                    onClick={this.save.bind(this)}
                  >
                    <SaveIcon className={classes.leftIcon} />
                    {
                      workspace
                        ? 'Save'
                        : 'Create'
                    }
                  </Button>
                </DialogActions>
              </div>
            )
          }
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormWorkspace));
