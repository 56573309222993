import React from 'react';
// import PropTypes from 'prop-types';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

// styles
import styles from './styles';

const useStyles = makeStyles(styles);

export default function ColoredButton({children, ...other}) {
  const classes = useStyles(other);

  return <Button {...other} color="primary" className={classes.root}>{children}</Button>;
}