import React from 'react';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';

// styles
import styles from './styles';

const useStyles = makeStyles(styles);

export default function RoundedChar({content, variant = "default", onClick}) {

  const classes = useStyles({variant});

  return <Avatar className={classes.avatar} onClick={onClick}>
    <Typography className={classes.content}>{content}</Typography>
  </Avatar>;
}



