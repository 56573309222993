import { REQUEST_INITIAL_STATE, CLEAN_STATE, MESSAGE_INIT, MESSAGE_ERROR, MESSAGE_SENT, MESSAGE_DELIVERED } from '../constants';
import {
  GET_MESSAGES_BY_ASSIGNMENT_ID,
  CREATE_MESSAGE,
  NEW_MESSAGE
} from '../constants';
import { handleLoading, handleSuccess, handleError, queryParamsToObject } from 'helpers';

const initialState = {
  messageList: [],
  createMessageTracker: {}
};

export default (state = initialState, {type, payload, args}) => {
  const cleanableStates = [GET_MESSAGES_BY_ASSIGNMENT_ID];
  const messageUUID = args && args[2];
  let currentUser = args && args[3];

  switch (type) {

    case `${GET_MESSAGES_BY_ASSIGNMENT_ID}_LOADING`:
      return handleLoading({state, action: GET_MESSAGES_BY_ASSIGNMENT_ID});
    case `${GET_MESSAGES_BY_ASSIGNMENT_ID}_SUCCESS`:
      state = handleSuccess({state, action: GET_MESSAGES_BY_ASSIGNMENT_ID, payload});
      return {
        ...state,
        messageList: payload
      };
    case `${GET_MESSAGES_BY_ASSIGNMENT_ID}_ERROR`:
      return handleError({state, action: GET_MESSAGES_BY_ASSIGNMENT_ID, payload});

    case NEW_MESSAGE:
      const {id} = queryParamsToObject(window.location.search);
      currentUser = payload.message.currentUser;
      const message = payload.message.data.body;
      if (currentUser.uuid === message.senderUUID) {
        return {
          ...state,
          messageList: state.messageList.map(m => {
            if (m.messageUUID === message.messageUUID) {
              return message;
            }
            return m;
          }),
          createMessageTracker: {
            ...state.createMessageTracker,
            [message.messageUUID]: MESSAGE_DELIVERED
          }
        };
      } else {
        if (id && id.toString() !== message.assignmentID.toString()) {
          return state;
        }
        return state = {
          ...state,
          messageList: [...state.messageList, message]
        };
      }

    case `${CREATE_MESSAGE}_LOADING`:
      const newMessage = {
        senderUUID: currentUser.uuid, displayName: `${currentUser.firstName} ${currentUser.lastName}`,
        createdAt: new Date(), content: args[0], assignmentID: args[1], messageUUID: args[2]
      };
      return {
        ...state,
        messageList: [...state.messageList, newMessage],
        createMessageTracker: {
          ...state.createMessageTracker,
          [newMessage.messageUUID]: MESSAGE_INIT
        }
      };

    case `${CREATE_MESSAGE}_SUCCESS`:
      const currentState = state.createMessageTracker[messageUUID];
      return {
        ...state,
        createMessageTracker: {
          ...state.createMessageTracker,
          [messageUUID]: currentState === MESSAGE_DELIVERED ? MESSAGE_DELIVERED : MESSAGE_SENT
        }
      };

    case `${CREATE_MESSAGE}_ERROR`:
      return {
        ...state,
        createMessageTracker: {
          ...state.createMessageTracker,
          [messageUUID]: MESSAGE_ERROR
        }
      };

    case CLEAN_STATE:
      if (cleanableStates.includes(payload.state)) {
        return {
          ...state,
          [payload.state]: REQUEST_INITIAL_STATE
        };
      }
      return state;

    default:
      return state;
      
  }
};
