import grey from '@material-ui/core/colors/grey';

export default theme => ({
  badge: {
    color: 'white'
  },
  displayTime: {
    marginBottom: theme.spacing(2),
    fontSize: 14
  },
  displayName: {
    marginBottom: 5
  },
  content: {
    position: 'relative',
    borderRadius: 4
  },
  snackbar: {
    position: 'absolute',
    bottom: 10
  },
  chip: {
    fontWeight: 500,
    fontSize: 16,
    padding: `0 ${theme.spacing(3)}px`
  },
  messageInputContainer: {
    backgroundColor: grey[200]
  }
});
