import React from 'react';
// import PropTypes from 'prop-types';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import WarningRoundedIcon from '@material-ui/icons/WarningRounded';

// styles
import styles from './styles';

import IssueListItem from 'hooks/IssueListItem';
import ColoredTextTypography from 'hooks/ColoredTextTypography';

const useStyles = makeStyles(styles);

export default function IssueList({issues, scrollableHeight, onDismiss, ...other}) {
  // const [ state, setState ] = React.useState(initialValue);
  const classes = useStyles(styles);

  const props = {
    bgcolor: "white",
    height: 'calc(100vh - 130px)',
    ...other
  };

  const scrollableProps = {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    height: scrollableHeight || 'calc(100vh - 172px)',
    p: 2
  };

  return <Box {...props} className={classes.content}>
    <Box {...scrollableProps}>
      <Box display="flex" justifyContent="center" mb={3}>
        <WarningRoundedIcon className={classes.icon} />
        <Box display="flex" flexDirection="column">
          <Box fontWeight="fontWeightBold" fontSize="h5.fontSize">Issues</Box>
          <ColoredTextTypography fontWeight="fontWeightBold" color="grey" shade="600">
            { issues.length } issues
          </ColoredTextTypography>
        </Box>
      </Box>
      
      {
        issues.map(i => <IssueListItem key={i.id} issue={i} onClick={(e) => onDismiss(e, i)} />)
      }
    </Box>
  </Box>;
}

