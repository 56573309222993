export * from './methods';
export * from './routes';
export * from './errors';
export * from './regexp';
export * from './app';
export * from './routeNames';
export * from './users';
export * from './groups';
export * from './roles';
export * from './rules';
export * from './serviceNames';
export * from './workspaces';
export * from './areas';
export * from './tripReports';
export * from './path';
export * from './loading';
export * from './assignments';
export * from './centrifuge';
export * from './chat';
export * from './files';

export const CLEAN_STATE = 'CLEAN_STATE';
export const REQUEST_INITIAL_STATE = {
  loading: false,
  success: false,
  message: '',
  data: null
};

export * from './stops';
export * from './interactions';
