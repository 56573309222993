import { serviceRoles } from "config/access";
import { WORKFLOW } from "constants/routes";
import { checkAccess } from "helpers/auth";
import WorkflowPage from "./container/WorkflowPage";

export default function getSettingsRoute(userAuth) {
  const { account, workflow } = serviceRoles;
  const requireAuth = {
    [account.name]: [account.roles.superadmin],
    [workflow.name]: [workflow.roles.dispatcher],
  };
  return {
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    path: WORKFLOW,
    withSidebar: true,
    withAppBar: true,
    component: WorkflowPage,
  };
}
