import InputAdornment from "@material-ui/core/InputAdornment";
// import PropTypes from 'prop-types';
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import React from "react";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function RoundedTextField({
  children,
  startIcon,
  endIcon,
  bordered,
  color,
  ...other
}) {
  // const [ state, setState ] = React.useState(initialValue);
  const classes = useStyles({ ...other, color });

  return (
    <TextField
      {...other}
      size="small"
      InputProps={{
        classes: {
          root: classes.textField,
          notchedOutline: bordered ? undefined : classes.noBorder,
        },
        startAdornment: startIcon && (
          <InputAdornment position="start"> {startIcon} </InputAdornment>
        ),
        endAdornment: endIcon && (
          <InputAdornment position="end"> {endIcon} </InputAdornment>
        ),
      }}
    >
      {children}
    </TextField>
  );
}
