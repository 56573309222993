import * as colors from "@material-ui/core/colors";

export default (theme) => ({
  noBorder: {
    border: "none",
  },
  textField: {
    color: (props) => {
      if (props.textColor === "white") {
        return "white";
      }
      const color = props.textColor || "grey";
      const shade = props.textShade || 900;
      return colors[color][shade];
    },
    borderRadius: 40,
    backgroundColor: (props) => {
      if (props.color === "white") {
        return "white";
      }
      const color = props.color || "grey";
      const shade = props.shade || 200;
      return colors[color][shade];
    },

    "& .MuiSelect-icon": {
      display: "none",
    },
  },
});
