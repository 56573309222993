import {
  GET,
} from 'constants/methods';

const service = 'v1';
const areas = `/${service}/areas`;

export function getAreas() {
  const url = `${areas}`;
  return {
    method: GET,
    url,
  };
}
