import blue from "@material-ui/core/colors/blue";
import green from "@material-ui/core/colors/green";

export default (theme) => ({
  container: {
    padding: 5,
  },
  paper: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
    borderColor: (props) => (props.completed ? green[500] : undefined),
    background: (props) => (props.completed ? green[50] : "white"),
  },
  button: {
    textTransform: "none",
    borderRadius: 10,
    background: "white",
  },
  openFileButton: {
    color: blue[500],
  },
  input: {
    background: "rgba(155,155,155,0.1)",
  },
});
