import {
  GET,
} from 'constants/methods';

import { REACT_APP_API_PREFIX } from 'config/env';

const files = `${REACT_APP_API_PREFIX}/file-service/files`;

export function getFileById(id) {
  const url = `${files}/${id}`;
  return {
    method: GET,
    url,
  };
}