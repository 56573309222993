export default (theme) => ({
  container: {
    padding: 16,
    width: 340,
  },
  addButton: {
    background: "#2196f3",
    color: "white",
    textTransform: "none",
    marginBottom: 16,
    "&:hover": {
      background: "#1565c0",
      color: "white",
    },
  },
});
