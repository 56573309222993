import { combineReducers } from "redux"; //eslint-disable-line
import app from "./app";
import areas from "./areas";
import assignments from "./assignments";
import centrifuge from "./centrifuge";
import chat from "./chat";
import errors from "./errors";
import files from "./files";
import groups from "./groups";
import nodes from "./nodes";
import roles from "./roles";
import rules from "./rules";
import serviceNames from "./serviceNames";
import storedArea from "./storedArea";
import user from "./user";
import userApps from "./userApps";
import users from "./users";
import workspaces from "./workspaces";
export default combineReducers({
  errors,
  user,
  app,
  users,
  groups,
  storedArea,
  roles,
  rules,
  userApps,
  serviceNames,
  workspaces,
  files,
  areas,
  assignments,
  centrifuge,
  chat,
  nodes,
});
