import { AREA_CHANNEL, NEW_MESSAGE, CLEAN_STATE } from '../constants';

const initialState = {
  [AREA_CHANNEL]: {}
}

export default (state = initialState, {type, payload}) => {
  const cleanableStates = [AREA_CHANNEL];

  switch (type) {
    case NEW_MESSAGE:
      return {
        ...state,
        [payload.channel]: payload.message
      };

    case CLEAN_STATE:
      if (cleanableStates.includes(payload.state)) {
        return { ...state, [payload.state]: {} };
      }
      return state;

    default:
      return state;
  }
};