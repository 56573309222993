import * as Api from "api";
import { networkAction } from "helpers/network/networkAction";
import {
  COMMAND,
  CREATE_ASSIGNMENT,
  CREATE_EMPTY_TRAILER,
  CREATE_STOP,
  CREATE_TASK,
  DELETE_ASSIGNMENT,
  DISMISS_ISSUE,
  GET_ASSIGNMENTS,
  GET_ASSIGNMENT_BY_ID,
  GET_GPS_UPDATE_BY_ASSIGNMENT_ID,
  GET_NODES,
  SEARCH_LOCATION,
  SET_SEARCH_DRIVER,
  SYNC_DEVICE,
  UPDATE_ASSIGNMENT,
} from "../constants";

export const setSearchDriver = (search) => async (dispatch) =>
  dispatch({ type: SET_SEARCH_DRIVER, payload: search });

export const getAssignments = (area, date) => async (dispatch) =>
  networkAction(dispatch, GET_ASSIGNMENTS, Api.getAssignments, [
    parseInt(area),
    date,
  ]);

export const getAssignmentById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_ASSIGNMENT_BY_ID, Api.getAssignmentById, [id]);

export const syncDevice = (id) => async (dispatch) =>
  networkAction(dispatch, SYNC_DEVICE, Api.syncDevice, [id]);

export const command = (id, body) => async (dispatch) =>
  networkAction(dispatch, COMMAND, Api.command, [id, body]);

export const deleteAssignment = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_ASSIGNMENT, Api.deleteAssignment, [id]);

export const createAssignment = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_ASSIGNMENT, Api.createAssignment, [body]);

export const updateAssignment = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_ASSIGNMENT, Api.updateAssignment, [id, body]);

export const dismissIssue = (issueID) => async (dispatch) =>
  networkAction(dispatch, DISMISS_ISSUE, Api.dismissIssue, [
    parseInt(issueID, 10),
  ]);

export const searchLocation = (area, location) => async (dispatch) =>
  networkAction(dispatch, SEARCH_LOCATION, Api.searchLocation, [
    area,
    location,
  ]);

export const getNodes = (area, location) => async (dispatch) =>
  networkAction(dispatch, GET_NODES, Api.getNodes, [area, location]);

export const createTask = (task) => async (dispatch) =>
  networkAction(dispatch, CREATE_TASK, Api.createTask, [task]);

export const createStop = (stop) => async (dispatch) =>
  networkAction(dispatch, CREATE_STOP, Api.createStop, [stop]);

export const createEmptyTrailer = (job) => async (dispatch) =>
  networkAction(dispatch, CREATE_EMPTY_TRAILER, Api.createEmptyTrailer, [job]);

export const getGpsUpdatesAssignmentId = (id) => async (dispatch) =>
  networkAction(
    dispatch,
    GET_GPS_UPDATE_BY_ASSIGNMENT_ID,
    Api.getGpsUpdatesAssignmentId,
    [id]
  );
