import {
  Chip,
  FormControl,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import DoneIcon from "@material-ui/icons/Done";
import LaunchIcon from "@material-ui/icons/Launch";
import * as Actions from "actions";
import { REACT_APP_API, REACT_APP_API_PREFIX } from "config/env";
import { pluralize } from "helpers";
import CheckboxSwitch from "hooks/CheckboxSwitch";
import ColoredChip from "hooks/ColoredChip";
import ColoredTextTypography from "hooks/ColoredTextTypography";
import moment from "moment";
import React from "react";
import { useDispatch } from "react-redux";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function TaskInteractor({ interactor, edit }) {
  const [value, setValue] = React.useState("");
  const classes = useStyles({ completed: interactor.completed });
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (interactor) {
      setValue(interactor.state?.value);
    }
  }, [interactor]);

  function createInteraction(v) {
    const _value = v || value;
    if (_value !== interactor.state?.value)
      dispatch(
        Actions.createInteraction({
          payload: {
            value: _value,
            timestamp: moment().utc(),
          },
          interactorID: interactor.id,
        })
      );
  }

  const printEditableInteractorValue = () => {
    switch (interactor.kind) {
      case "textfield":
        return (
          <InputBase
            className={classes.input}
            size="small"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onBlur={() => createInteraction()}
            placeholder={interactor.description}
          />
        );
      case "combobox":
        return (
          <FormControl style={{ minWidth: 200 }}>
            <InputLabel id="demo-simple-select-label"></InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              className={classes.input}
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
                createInteraction(e.target.value);
              }}
            >
              {interactor.comboStore?.map((i) => (
                <MenuItem value={i}>{i}</MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      case "numberfield":
        return (
          <InputBase
            className={classes.input}
            type="number"
            onBlur={() => createInteraction()}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder={interactor.description}
          />
        );
      case "signature":
        return <div>You can't sign for the driver</div>;
      case "checkbox":
        return (
          <CheckboxSwitch
            onChange={(e) => {
              setValue(e);
              createInteraction(e);
            }}
            checked={value === "1"}
          />
        );
      case "fileUpload":
        return <div>You can't upload files for the driver</div>;
      case "button":
        return (
          <Chip
            style={{
              background: interactor.state.value && "#4caf50",
              color: interactor.state.value && "white",
            }}
            icon={
              interactor.state.value && <DoneIcon style={{ color: "white" }} />
            }
            onChange={() => {
              setValue(interactor.description);
              createInteraction(interactor.description);
            }}
            label={interactor.description}
          />
        );

      default:
        return (
          <Typography>
            {`Unsupported Interactor: ${interactor.kind}`}
          </Typography>
        );
    }
  };

  const printInteractorValue = () => {
    if (interactor.completed && interactor.interactions.length) {
      switch (interactor.kind) {
        case "textfield":
        case "numberfield":
        case "fileUpload":
        case "combobox":
        case "button":
          const props = { color: "green", label: interactor.state.value };
          if (interactor.kind === "fileUpload") {
            const quantity = interactor.interactions.filter(
              (i) => i.payload && i.payload.id
            ).length;
            props.label = `${quantity} ${pluralize("file", quantity)}`;
          }
          if (interactor.kind === "button") {
            props.icon = <DoneIcon style={{ color: "white" }} />;
          }
          if (interactor.kind === "textfield" && props.label.length > 20) {
            return;
          }
          return <ColoredChip {...props}></ColoredChip>;
        case "signature":
          return (
            <Paper variant="outlined">
              <img
                alt="Signature"
                src={interactor.state.value}
                width="100%"
                height="60px"
              />
            </Paper>
          );
        case "checkbox":
          return <CheckboxSwitch checked={interactor.state.value === "1"} />;
        default:
          return (
            <Box fontWeight="fontWeightBold">{interactor.state.value}</Box>
          );
      }
    } else {
      return (
        <Box display="flex" alignItems="center">
          <ColoredTextTypography color="grey" shade="400" fontSize={14}>
            Not Set
          </ColoredTextTypography>
        </Box>
      );
    }
  };

  const handleOpenFile = async (id) => {
    window.open(
      `${REACT_APP_API}${REACT_APP_API_PREFIX}/file-service/files/${id}?inline=1`,
      "_blank"
    );
  };

  const printInteractions = () => {
    if (interactor.completed) {
      if (interactor.kind === "fileUpload") {
        return interactor.interactions
          .filter((i) => i.payload && i.payload.id)
          .map((i) => (
            <Button
              key={i.id}
              className={classes.openFileButton}
              onClick={() => handleOpenFile(i.payload.id)}
              fullWidth
              endIcon={<LaunchIcon />}
            >
              Open (ID: {i.payload.id})
            </Button>
          ));
      }
      if (
        interactor.kind === "textfield" &&
        interactor.state.value &&
        interactor.state.value.length > 20
      ) {
        return (
          <Paper>
            <Box py={1} px={2}>
              {value}
            </Box>
          </Paper>
        );
      }
    }
  };

  return (
    <Grid item xs={12}>
      <Paper variant="outlined" className={classes.paper}>
        <Box display="flex" justifyContent="space-between" py={1}>
          <Box display="flex" flexDirection="column" justifyContent="center">
            <Box fontWeight="fontWeightBold" fontSize={14}>
              {" "}
              {interactor.title}{" "}
            </Box>
            <ColoredTextTypography color="grey" shade="600" fontSize={12}>
              {interactor.description}
            </ColoredTextTypography>
          </Box>
          {edit ? printEditableInteractorValue() : printInteractorValue()}
        </Box>
        {printInteractions()}
      </Paper>
    </Grid>
  );
}
