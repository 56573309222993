import {
  GET_WORKSPACES,
} from 'constants/workspaces';

export default (state = [], action) => {
    switch (action.type) {
    case `${GET_WORKSPACES}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
