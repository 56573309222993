import { CLEAN_STATE } from "../constants";

export * from "./app";
export * from "./areas";
export * from "./assignments";
export * from "./centrifuge";
export * from "./chat";
export * from "./errors";
export * from "./files";
export * from "./groups";
export * from "./roles";
export * from "./rules";
export * from "./serviceNames";
export * from "./sessions";
export * from "./stops";
export * from "./tripReports";
export * from "./users";
export * from "./workspaces";
export const cleanState = (state) => async (dispatch) =>
  dispatch({ type: CLEAN_STATE, payload: { state } });
export * from './interactions';
