export default theme => ({
  wrapper: {
    position: 'relative',
    height: '100%',
    width: '100%'
  },
  map: {
    borderRadius: 10,
    overflow: 'hidden'
  },
  configPanel: {
    top: 2,
    right: 2,
    zIndex: 1,
    position: 'absolute'
  }
});
