import React from 'react';
import { format, isToday } from 'date-fns';
import { useSelector } from 'react-redux';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Slide from '@material-ui/core/Slide';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';

import { MESSAGE_INIT, MESSAGE_DELIVERED, MESSAGE_ERROR, MESSAGE_SENT } from '../../constants';

// styles
import styles from './styles';

const useStyles = makeStyles(styles);

export default function MessageBubble({message, children, last, date = new Date(), variant = "sender"}) {
  const [ expanded, setExpanded ] = React.useState(false);
  const [ actualFormat ] = React.useState(isToday(date) ? 'H:mm aa' : 'MMM do H:mm');
  const classes = useStyles({variant, expanded, last, gutterBottom: Boolean(message.displayTime)});

  const {createMessageTracker} = useSelector(state => state.chat);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  }

  const renderNewMessageTrackingInfo = () => {
    const newMessageState = createMessageTracker[message.messageUUID];
    if (newMessageState) {
      switch(newMessageState) {
        case MESSAGE_INIT:
          return <ErrorOutlineIcon className={classes.newMessageTrackingIcon} color="warning" />

        case MESSAGE_ERROR:
          return <ErrorOutlineIcon className={classes.newMessageTrackingIcon} color="error" />

        case MESSAGE_SENT:
          return <DoneIcon className={classes.newMessageTrackingIcon} />

        case MESSAGE_DELIVERED:
          return <DoneAllIcon className={classes.newMessageTrackingIcon} />

        default:
          return <ErrorOutlineIcon className={classes.newMessageTrackingIcon} color="warning" />
      }
    }
    return;
  }

  return <Slide direction="up" mountOnEnter unmountOnExit in>
    <Tooltip title={format(date, actualFormat)} arrow>
      <div onClick={handleExpandClick} className={classes.container}>
        { renderNewMessageTrackingInfo() }
        <span className={classes.message}>
          {children}
        </span>
      </div>
    </Tooltip>
  </Slide>;
}

