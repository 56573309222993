import grey from '@material-ui/core/colors/grey';
import blue from '@material-ui/core/colors/blue';

export default theme => ({
  container: {
    borderBottom: '1px solid',
    borderBottomColor: grey[200],
    padding: theme.spacing(1),
    '&:hover': {
      background: blue[100],
   }
  },
  active: {
    background: blue[50]
  },
});
