import { REACT_APP_API_PREFIX } from "config/env";
import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "workflow";
const interactions = `${REACT_APP_API_PREFIX}/${service}/interactions`;

export function getInteractions(filters) {
  const url = `${interactions}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getInteractionById(id) {
  const url = `${interactions}/${id}/full`;
  return {
    method: GET,
    url,
  };
}

export function updateInteraction(id, body) {
  const url = `${interactions}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteInteraction(id) {
  const url = `${interactions}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createInteraction(body) {
  const url = `${interactions}`;
  return {
    method: POST,
    url,
    body,
  };
}
