import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// actions
import * as Actions from 'actions';

// helpers
import { getJsonFromUrl } from 'helpers';

// component
import WrapperMyAccountPage from '../component/WrapperMyAccountPage';

const mapStateToProps = state => ({
  user: state.user,
  userApps: state.userApps,
  accessTokens: state.accessTokens,
});

const mapDispatchToProps = dispatch => ({
  getCurrentUser: (...args) => dispatch(Actions.getCurrentUser(...args)),
  updateUser: (...args) => dispatch(Actions.updateUser(...args)),
  updateUserPassword: (...args) => dispatch(Actions.updateUserPassword(...args)),
});
class MyAccountPage extends React.Component {
  static propTypes = {
    user: PropTypes.object,
    userApps: PropTypes.array,
    getCurrentUser: PropTypes.func,
    updateUser: PropTypes.func,
    updateUserPassword: PropTypes.func,
    history: PropTypes.object,
    location: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { location } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
    };
  }

  componentDidMount() {
    this.refresh();
  }

  refresh() {
    const { getCurrentUser } = this.props;

    return Promise.all([
      getCurrentUser(),
    ]);
  }

  render() {
    const {
      urlParams,
    } = this.state;

    const {
      user,
      history,
      updateUser,
      updateUserPassword,
      userApps,
    } = this.props;

    return (
      <WrapperMyAccountPage
        history={history}
        refresh={this.refresh.bind(this)}
        user={user}
        updateUser={updateUser}
        updateUserPassword={updateUserPassword}
        urlParams={urlParams}
        userApps={userApps}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(MyAccountPage);
