import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

// material-ui
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

// constants
import {
  icons,
} from 'constants/path';


// styles
import styles from './styles';

class Path extends Component {
  static propTypes = {
    classes: PropTypes.object,
    path: PropTypes.object,
    first: PropTypes.bool,
    last: PropTypes.bool,
    darkMode: PropTypes.object,
    onSelect: PropTypes.func,
    selected: PropTypes.object,
    driveTime: PropTypes.object,
  };

  state = {
    hover: false,
  };

  render() {
    const {
      path,
      first,
      last,
      onSelect,
      selected,
      darkMode,
      driveTime,
    } = this.props;


    const { hover } = this.state;
    const Icon = icons[path.vertex_sub_type];

    const issued = path.issue.name !== undefined;

    let color = darkMode ? '#bdbdbd' : '#424242';
    let iconColor = issued ? '#EC6B1C' : 'white';
    if (issued) color = '#EC6B1C';
    let isSelected;
    if (selected) {
      isSelected = `${selected.address.address_id}_${selected.arrival_time}` === `${path.address.address_id}_${path.arrival_time}`;
    }

    let background = hover ? 'rgba(100, 100, 100, 0.2)' : undefined;
    if (isSelected) {
      background = issued ? '#EC6B1C' : '#2196f3';
      color = 'white';
      iconColor = issued ? '#EC6B1C' : '#2196f3';
    }

    const stayTime = moment.utc(moment(path.departure_time).diff(moment(path.arrival_time))).format('H:mm');

    return (
      <div
        style={{
          position: 'relative',
          width: 90,
          height: 100,
          paddingTop: 35,
          marginBottom: 12,
        }}
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
        onClick={() => onSelect(path)}
      >
        <Typography
          style={{
            transform: 'rotate(-45deg)',
            height: 40,
            width: 110,
            overflow: 'hidden',
            fontWeight: 'bold',
            color,
            padding: 4,
            borderRadius: 4,
            background,
            cursor: 'pointer',
          }}
        >
          {path.name}
        </Typography>
        <div
          style={{
            height: 15,
            borderTopLeftRadius: first ? 8 : 0,
            borderBottomLeftRadius: first ? 8 : 0,
            borderTopRightRadius: last ? 8 : 0,
            borderBottomRightRadius: last ? 8 : 0,
            background: darkMode ? '#757575' : '#424242',
            width: last ? '23px' : '100%',
            color: iconColor,
            lineHeight: 1,
            position: 'absolute',
            bottom: 0,
          }}
        >
          <Icon style={{ height: 15 }} />
          <div
            style={{
              fontSize: 10,
              position: 'absolute',
              top: 2,
              left: 48,
              color: '#9e9e9e',
            }}
          >
            {driveTime}
          </div>
          <Typography
            style={{
              fontSize: 10,
              color: isSelected ? iconColor : undefined,
            }}
          >
            {stayTime}
          </Typography>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Path);
