import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Fab from '@material-ui/core/Fab';
import Popover from '@material-ui/core/Popover';

// styles
import styles from './styles';
import SearchField from 'hooks/SearchField';
import DriverListItem from 'hooks/DriverListItem';

const useStyles = makeStyles(styles);

export default function DriverList({list, activeItem, onSearch, onSelect, variant = "default"}) {
  
  const classes = useStyles({variant});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const fabRef = React.useRef(null);

  useEffect(() => {
    fabRef.current && fabRef.current.click();
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnSelect = (e, item) => {
    handleClose();
    onSelect(e, item);
  }

  const buildBody = () => {
    var content = <Box boxShadow={2} bgcolor="white" className={classes.container}>

      <Box className={classes.searchBox} p={1}>
        <Box fontSize="h6.fontSize" fontWeight="fontWeightBold" mb={1}>Drivers</Box>
        <SearchField onSubmit={onSearch} />
      </Box>

      <Box className={classes.driverList}>
        {
          list && list.map(item => {
            return <DriverListItem key={item.id} item={item} active={item.id.toString() === activeItem.toString()} onClick={(e) => handleOnSelect(e, item)}/>;
          })
        }
      </Box>

    </Box>;

    if (variant === "floating") {
      return <div>
        <Fab ref={fabRef} aria-describedby='driverList-popover' 
          className={classes.fab}
          color="secondary" 
          variant="extended"
          aria-label="drivers"
          onClick={handleClick}
        >
          Drivers
        </Fab>
        <Popover
          id='driverList-popover'
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          {content}
        </Popover>
      </div>
    }
    return content;
  }

  return buildBody();
}

