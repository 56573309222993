import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';


// actions
// import * as Actions from 'actions';

// component
import WrapperWorkflowPage from '../component/WrapperWorkflowPage';

const mapStateToProps = state => ({
  ...state,
});

const mapDispatchToProps = dispatch => ({
  // signin: (...args) => dispatch(Actions.signin(...args)),
});
class WorkflowPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
  };

  render() {
    const {
      location,
      history,
    } = this.props;

    return (
      <WrapperWorkflowPage
        history={history}
        location={location}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(WorkflowPage);
