import { Icon } from "@material-ui/core";
import Box from "@material-ui/core/Box";
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import classNames from "classnames";
import React from "react";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function DriverListItem({ item, active, onClick }) {
  // const [ state, setState ] = React.useState(initialValue);
  const classes = useStyles(styles);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      onClick={onClick}
      className={classNames(classes.container, { [classes.active]: active })}
    >
      <Box fontSize="fontSize" fontWeight="fontWeightBold">
        {item.userName.toUpperCase()}
      </Box>
      {!item.resolved && (
        <Icon class="fa-solid fa-comment-dots" style={{ color: "#f44336" }} />
      )}
      {item.issues && (
        <Tooltip
          title={
            <div style={{ whiteSpace: "pre-line" }}>
              {item.issues
                .map((issue) => `${issue.name}\n${issue.createdAt}`)
                .join("\n\n")}
            </div>
          }
        >
          <Icon
            class="fa-solid fa-triangle-exclamation"
            style={{ color: "#ff9800" }}
          />
        </Tooltip>
      )}
      <ArrowRightIcon />
    </Box>
  );
}
