import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { camelCaseToTitleCase } from "helpers";
import TaskInteractor from "hooks/TaskInteractor";
import moment from "moment-timezone";
import React from "react";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function TaskListItem({ task, tz = "UTC", edit }) {
  // const [ state, setState ] = React.useState(initialValue);
  const classes = useStyles(styles);

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-around"
      alignItems="stretch"
    >
      <Grid item xs={12}>
        <Paper variant="outlined" className={classes.paper}>
          <Box mb={2}>
            <Box display="flex" justifyContent="space-between">
              <Box fontWeight="fontWeightBold" fontSize="h6.fontSize">
                {camelCaseToTitleCase(task.kind)}
              </Box>
              <Box display="flex" alignItems="center" fontSize="16px">
                {task.time && (
                  <>
                    <AccessTimeIcon />{" "}
                    <Box ml={1}>
                      {moment.tz(task.time, tz).format("HH:mm A")}{" "}
                    </Box>
                  </>
                )}
              </Box>
            </Box>
            {task.loadID !== 0 && (
              <Box fontSize={14}>
                #{task.loadID}{" "}
                {task.referenceNumber ? `(${task.referenceNumber})` : ""}{" "}
              </Box>
            )}
            {task.serviceLevel && (
              <Box> Service Level: {task.serviceLevel} </Box>
            )}
          </Box>

          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="stretch"
          >
            {task.interactors
              .filter(
                (interactor) =>
                  !interactor.completed ||
                  (interactor.completed && interactor.interactions.length)
              )
              .map((interactor) => (
                <TaskInteractor
                  edit={edit}
                  key={interactor.id}
                  interactor={interactor}
                />
              ))}
            <Grid item xs={12} style={{ textAlign: "right" }}>
              <Typography variant="caption" color="textSecondary">
                Task #{task.id}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}
