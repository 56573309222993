import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import withStyles from '@material-ui/core/styles/withStyles';

// helpers
import { getJsonFromUrl } from 'helpers';

// styles
import styles from './styles';

class UrlActions extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    location: PropTypes.object,
    history: PropTypes.object,
    validateEmail: PropTypes.func,
    storeResetPasswordToken: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  componentWillMount() {
    const { location, storeResetPasswordToken } = this.props;
    const search = getJsonFromUrl(location);
    const { localStorage } = window;

    for (const k in search) {
      if (search.hasOwnProperty(k)) {
        switch (k) {
          case 'activateToken':
            this.activateEmail(search[k]);
            break;
          case 'resetPassword':
            storeResetPasswordToken(search[k]);
            break;
          case 'redirectTo':
            localStorage.redirectUrl = search[k];
            break;
          default:
        }
      }
    }
  }

  async activateEmail(token) {
    const { validateEmail, history } = this.props;
    const { NotificationCenter } = this.context;

    const resp = await validateEmail(token);
    if (resp.success) {
      NotificationCenter.sweetAlert(
        {
          title: 'Good Job !',
          subtitle: 'You email has been successfully confirmed.',
          body: 'You can now start using the portal',
          success: true,
          timestamp: new Date().getTime(),
        },
        {
          confirm: {
            label: 'Go to the Portal',
            level: 'success',
            callback: () => history.push('/'),
          },
        },
      );
    }
  }

  render() {
    const { children } = this.props;

    return (
      <div>
        {children}
      </div>
    );
  }
}

export default withStyles(styles)(UrlActions);
