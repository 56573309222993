import {
  GET,
  POST,
} from 'constants/methods';

import { REACT_APP_API_PREFIX } from 'config/env';


const service = 'workflow';
const messages = `${REACT_APP_API_PREFIX}/${service}/messages`;

export function getMessagesByAssignmentId(id) {
  const filters = [{name: 'messages.assignmentID', comparison: 'eq', value: id}];
  const url = `${messages}?filters=${JSON.stringify(filters)}`;
  return {
    method: GET,
    url,
  };
}

export function createMessage(content, assignmentID, messageUUID) {
  const body = {content, assignmentID, messageUUID};
  return {
    method: POST,
    url: `${messages}`,
    body
  };
}