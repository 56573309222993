import blue from '@material-ui/core/colors/blue';

export default theme => ({
  form: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'start',
    alignItems: 'center',

    '& .MuiFormControl-root': {
      padding: `0 ${theme.spacing(1)}px`
    }
  },
  select: {
    background: 'red'
  },
  appBar: {
    height: 50,
    background: blue[600]
  },
  container: {
    flexGrow: 1,
    display: 'flex',
    height: 'calc(100vh - 97px)',
    position: 'relative'
  },
  noBorder: {
    border: 'none'
  },
  datePicker: {
    color: 'white',
    borderRadius: 40,
    padding: '4px 12px',
    backgroundColor: blue[400]
  }
});
