import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import React from "react";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function CheckboxSwitch({ checked, onChange }) {
  // const [ state, setState ] = React.useState(initialValue);
  const classes = useStyles(styles);

  return (
    <Box
      className={classes.checkboxContainer}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Button
        size="small"
        variant={checked ? "text" : "contained"}
        onClick={onChange ? () => onChange("0") : undefined}
        className={classNames(classes.checkboxButton, { active: !checked })}
      >
        No
      </Button>
      <Button
        size="small"
        onClick={onChange ? () => onChange("1") : undefined}
        variant={checked ? "contained" : "text"}
        className={classNames(classes.checkboxButton, { active: checked })}
      >
        Yes
      </Button>
    </Box>
  );
}
