import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

// material-ui
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Warning from '@material-ui/icons/Warning';
import withWidth from '@material-ui/core/withWidth';

// component
import Trip from 'components/Trip';

// styles
import styles from './styles';

class TripReport extends Component {
  static propTypes = {
    classes: PropTypes.object,
    width: PropTypes.string,
    report: PropTypes.object,
    onSelect: PropTypes.func,
    app: PropTypes.object,
    selected: PropTypes.object,
  };

  render() {
    const {
      classes,
      report,
      onSelect,
      app,
      selected,
      width,
    } = this.props;

    const {
      driver,
      issues,
      path,
      results,
    } = report;

    let onDutyPercent = results.on_duty_time * 100 / 10.5;
    let onDutyColor = '#4caf50';
    if (onDutyPercent > 100) { onDutyPercent = 100; onDutyColor = '#ff9800'; }

    let driveTimePercent = results.driving_time * 100 / 14;
    let driveTimeColor = '#4caf50';
    if (driveTimePercent > 100) { driveTimePercent = 100; driveTimeColor = '#ff9800'; }

    return (
      <Paper
        className={classes.container}
        style={{
          borderLeft: issues.length ? 'solid 3px #ff9800' : 'solid 3px #4caf50',
        }}
      >
        <Grid container spacing={16} justify={width !== 'xs' ? 'space-between' : undefined}>
          <Grid item xs={width === 'xs' ? 12 : undefined}>
            <Typography variant="h6">
              {driver.driver_name ? driver.driver_name : 'Unkown Driver'}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {issues.length ? `${issues.length} Issue${issues.length > 1 ? 's' : ''}` : 'No issue found. Good job!'}
            </Typography>
          </Grid>
          <Grid item xs={width === 'xs' ? 12 : undefined}>
            <Grid container>
              <Grid item style={{ textAlign: 'center' }}>
                <div className={classes.circularContainer}>
                  <CircularProgress
                    className={classes.back}
                    variant="static"
                    value={100}
                  />
                  <CircularProgress
                    className={classes.fore}
                    variant="static"
                    value={onDutyPercent}
                    style={{
                      color: onDutyColor,
                    }}
                  />
                  <Typography style={{ color: onDutyColor }} className={classes.value}>
                    {Math.floor(results.on_duty_time * 100 / 10.5)}<span style={{ fontSize: 10 }}>%</span>
                  </Typography>
                </div>
                <Typography variant="caption">
                  On Duty Time
                </Typography>
                <Typography variant="caption" style={{ color: onDutyColor, fontWeight: 500, fontSize: 10 }}>
                  {Math.round(results.on_duty_time * 100) / 100}/10.5
                </Typography>
              </Grid>
              <Grid item style={{ textAlign: 'center' }}>
                <div className={classes.circularContainer}>
                  <CircularProgress
                    className={classes.back}
                    variant="static"
                    value={100}
                  />
                  <CircularProgress
                    className={classes.fore}
                    variant="static"
                    value={driveTimePercent}
                    style={{
                      color: driveTimeColor,
                    }}
                  />
                  <Typography style={{ color: driveTimeColor }} className={classes.value}>
                    {Math.floor(results.driving_time * 100 / 14)}<span style={{ fontSize: 10 }}>%</span>
                  </Typography>
                </div>
                <Typography variant="caption">
                  Driving Time
                </Typography>
                <Typography variant="caption" style={{ color: driveTimeColor, fontWeight: 500, fontSize: 10 }}>
                  {Math.round(results.driving_time * 100) / 100}/14
                </Typography>
              </Grid>
              <Grid item style={{ textAlign: 'right' }}>
                <Grid container style={{ width: 200 }}>
                  <Grid item xs={6}>
                    <Typography>
                      <b>Start:</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      {moment(results.start_time).format('LTS')}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      <b>Finish:</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      {moment(results.finish_time).format('LTS')}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      <b>Duration:</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      {moment.utc(moment(results.finish_time).diff(moment(results.start_time))).format("HH:mm:ss")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Trip
          trip={path}
          onSelect={p => onSelect(p, report)}
          selected={selected}
          darkMode={app.darkMode}
        />
        <br />
        {
            issues.map((issue, i) => (
              <Grid container spacing={8} key={`Chip_${i}_${issue.name}`} alignItems="center">
                <Grid item>
                  <Warning color="primary" />
                </Grid>
                <Grid item>
                  <Typography color="primary">
                    {issue.name}
                  </Typography>
                </Grid>
              </Grid>
            ))
          }
      </Paper>
    );
  }
}

export default withWidth()(withStyles(styles)(TripReport));
