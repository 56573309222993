export function zeroPadStart(str, n = 2) {
  return str.toString().padStart(n, '0');
}

export function pluralize(word, quantity, plural = 's') {
  return quantity > 1 ? `${word}${plural}` : word;
}

export function truncate(str, max, suffix = '...') {
  if (max > str.length){
    return str;
  } else{
    str = str.substring(0, max);
    return str + suffix;
  }
}

export function uuidV4() {
  return ('' + 1e7 + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (((c ^ crypto.getRandomValues(new Uint8Array(1))[0]) & 15) >> (c / 4)).toString(16)
  );
}

export function camelCaseToTitleCase(text) {
  const result = text.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
}