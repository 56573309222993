import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as Actions from "actions";
import useDebounce from "hooks/useDebounce";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SEARCH_LOCATION } from "../../constants";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function SearchLocationField({ ...other }) {
  const classes = useStyles(styles);
  const dispatch = useDispatch();

  const [locations, setLocations] = useState([]);
  const [searchLocation, setSearchLocation] = useState("");
  const storedArea = useSelector((state) => state.storedArea);

  const debouncedSearchLocation = useDebounce(searchLocation || " ", 1000);
  const searchLocationReq = useSelector((state) => state.assignments)[
    SEARCH_LOCATION
  ];

  const handleSubmit = useCallback(
    (s) => {
      if (s.trim()) {
        dispatch(Actions.searchLocation(storedArea, s));
      }
    },
    [dispatch, storedArea]
  );

  useEffect(() => {
    if (debouncedSearchLocation) {
      handleSubmit(debouncedSearchLocation);
    }
  }, [debouncedSearchLocation, handleSubmit]);

  useEffect(() => {
    if (searchLocationReq && searchLocationReq.success) {
      setLocations(searchLocationReq.data);
    }
  }, [searchLocationReq]);

  return (
    <Autocomplete
      {...other}
      options={locations}
      getOptionLabel={(option) => option.name}
      renderOption={(option) => (
        <Paper className={classes.paper}>
          <Box fontWeight="fontWeightBold" display="block">
            {option.name}
          </Box>
          <Typography variant="caption" display="block">
            {option.address.line1}
          </Typography>
          {option.address.line2 && (
            <Typography variant="overline" display="block">
              {option.line2}
            </Typography>
          )}
          <Typography
            variant="caption"
            display="block"
          >{`${option.address.city}, ${option.address.state} ${option.address.zip}`}</Typography>
        </Paper>
      )}
      style={{ width: 300 }}
      loading={searchLocationReq && searchLocationReq.loading}
      inputValue={searchLocation}
      onInputChange={(event, newInputValue) => {
        setSearchLocation(newInputValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label="Search location" variant="outlined" />
      )}
    />
  );
}
