export function sortBy(a, b, colKey, order = 'asc', type = 'string') {
  if (order === 'asc') {
    return sortUp(a, b, colKey, type);
  }
  if (order === 'desc') {
    return sortDown(a, b, colKey, type);
  }
  return sortUp(a, b, colKey, type);
}

export function sortUp(a, b, colKey, type) {
  let nameA;
  let nameB;

  if (type === 'numeric') {
    nameA = a[colKey] ? Number(a[colKey]) : -999999999;
    nameB = b[colKey] ? Number(b[colKey]) : -999999999;
  } else {
    nameA = a[colKey] ? String(a[colKey]).toLowerCase() : 'z';
    nameB = b[colKey] ? String(b[colKey]).toLowerCase() : 'z';
  }

  if (nameA > nameB) {
    return 1;
  }

  if (nameA < nameB) {
    return -1;
  }

  return 0;
}

export function sortDown(a, b, colKey, type) {
  let nameA;
  let nameB;

  if (type === 'numeric') {
    nameA = a[colKey] ? Number(a[colKey]) : -999999999;
    nameB = b[colKey] ? Number(b[colKey]) : -999999999;
  } else {
    nameA = a[colKey] ? String(a[colKey]).toLowerCase() : 'z';
    nameB = b[colKey] ? String(b[colKey]).toLowerCase() : 'z';
  }

  if (nameA < nameB) {
    return 1;
  }

  if (nameA > nameB) {
    return -1;
  }

  return 0;
}

export function intersect(a1, b1) {
  const i = a1.reduce((m, v) => {
    const n = m;
    n[v] = 1;
    return n;
  }, {});
  return b1.filter(v => i[v]);
}

export function union(x, y) {
  const obj = {};
  for (let i = x.length - 1; i >= 0; --i) {
    obj[x[i]] = x[i];
  }

  for (let i = y.length - 1; i >= 0; --i) {
    obj[y[i]] = y[i];
  }

  const res = [];

  for (const k in obj) {
    if (obj.hasOwnProperty(k)) {
      res.push(obj[k]);
    }
  }

  return res;
}

export function clone(a1) {
  return JSON.parse(JSON.stringify(a1));
}

export function transform(a1) {
  const a2 = JSON.parse(JSON.stringify(a1));
  for (const k in a2) {
    if (a2.hasOwnProperty(k)) {
      a2[k] = a2[k].id;
    }
  }

  return a2;
}

export function difference(a1, a2) {
  const a = [];
  const diff = [];

  for (let i = 0; i < a1.length; i++) {
    a[a1[i]] = true;
  }

  for (let i = 0; i < a2.length; i++) {
    if (a[a2[i]]) {
      delete a[a2[i]];
    } else {
      a[a2[i]] = true;
    }
  }

  for (const k in a) {
    if (a.hasOwnProperty(k)) {
      diff.push(k);
    }
  }

  return diff;
}

export function objectDifference(a1, a2) {
  const diff = [];

  for (const k in a1) {
    if (a1.hasOwnProperty(k)) {
      const itemA1 = a1[k];
      let found = false;

      for (const l in a2) {
        if (a2.hasOwnProperty(l)) {
          const itemA2 = a2[l];
          if (JSON.stringify(itemA1) === JSON.stringify(itemA2)) {
            found = true;
            break;
          }
        }
      }

      if (!found) {
        diff.push(itemA1);
      }
    }
  }

  return diff;
}
