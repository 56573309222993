import React from 'react';

export const TripReportLoading = [
  <rect x="73" y="36" rx="0" ry="0" width="110" height="0" />,
  <rect x="3" y="-5" rx="0" ry="0" width="6" height="149" />,
  <rect x="20" y="41" rx="0" ry="0" width="1" height="1" />,
  <rect x="28" y="6" rx="0" ry="0" width="220" height="23" />,
  <rect x="30" y="40" rx="0" ry="0" width="202" height="8" />,
  <rect x="31" y="103" rx="0" ry="0" width="482" height="12" />,
  <circle cx="943" cy="26" r="20" />,
  <circle cx="877" cy="25" r="21" />,
  <rect x="851" y="55" rx="0" ry="0" width="51" height="4" />,
  <rect x="923" y="52" rx="0" ry="0" width="46" height="7" />,
  <rect x="1033" y="7" rx="0" ry="0" width="109" height="19" />,
  <rect x="1033" y="33" rx="0" ry="0" width="131" height="18" />,
  <rect x="1031" y="58" rx="0" ry="0" width="131" height="17" />,
];

export const HistoryItemLoading = [
  <rect x="86" y="16" rx="4" ry="4" width="302" height="14" />,
  <rect x="88" y="40" rx="3" ry="3" width="302" height="12" />,
  <circle cx="40" cy="39" r="38" />,
];
