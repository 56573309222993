export default theme => ({
  container: {
    marginTop: 10,
    marginBottom: 20,
    padding: 10,
  },
  back: {
    color: 'rgba(127,127,127,0.5)',
  },
  fore: {
    position: 'absolute',
    left: 0,
  },
  circularContainer: {
    position: 'relative',
    margin: 'auto',
    width: 40,
  },
  value: {
    position: 'absolute',
    top: 10,
    textAlign: 'center',
    width: '100%',
  },
});
