// import PropTypes from 'prop-types';
// material-ui
import { Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import bobtail from "./bobtail.png";
import defaultImg from "./default.png";
import dispatch from "./dispatch.png";
import dropFreight from "./dropFreight.png";
import dropTrailer from "./dropTrailer.png";
import goToYard from "./goToYard.png";
import pickupFreight from "./pickupFreight.png";
import pickupTrailer from "./pickupTrailer.png";
import repairShop from "./repairShop.png";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function TaskKindIcon({ kind }) {
  // const [ state, setState ] = React.useState(initialValue);
  const classes = useStyles(styles);

  const kinds = {
    bobtail: bobtail,
    dispatch: dispatch,
    dropFreight: dropFreight,
    dropTrailer: dropTrailer,
    goToYard: goToYard,
    pickupFreight: pickupFreight,
    pickupTrailer: pickupTrailer,
    repairShop: repairShop,
  };

  return (
    <Tooltip title={kind}>
      <div style={{ opacity: 0.8, paddingTop: 2 }}>
        <img src={kinds[kind] ?? defaultImg} height={8} />
      </div>
    </Tooltip>
  );
}
