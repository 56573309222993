import blue from "@material-ui/core/colors/blue";

export default (theme) => ({
  avatar: {
    color: "white",
    backgroundColor: blue[600],
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  content: {
    fontWeight: 500,
    fontSize: 28,
  },
});
