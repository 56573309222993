import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Zoom from '@material-ui/core/Zoom';
import { withCookies } from 'react-cookie';

// styles
import styles from './styles';

class TopMessage extends React.Component {
  static propTypes = {
    cookies: PropTypes.any,
    callback: PropTypes.func,
    classes: PropTypes.object,
    icon: PropTypes.any,
    subtitle: PropTypes.any,
    body: PropTypes.any,
    action: PropTypes.any,
    cookie: PropTypes.object,
    position: PropTypes.string,
    background: PropTypes.string,
    lock: PropTypes.bool,
  };

  constructor(...args) {
    super(...args);
    const { cookie, cookies } = this.props;

    this.state = {
      confirmed: cookie ? cookies.get(cookie) : false,
    };
  }

  render() {
    const {
      classes,
      icon,
      subtitle,
      body,
      action,
      position,
      background,
      cookies,
      cookie,
      callback,
      lock,
    } = this.props;

    const { confirmed } = this.state;

    if (confirmed && lock !== true) {
      return <div />;
    }

    return (
      <ClickAwayListener
        onClickAway={() => this.setState({ confirmed: true })}
      >
        <Zoom in>
          <div
            style={{
              background,
              padding: 10,
              position: 'fixed',
              bottom: position === 'bottom' ? 0 : undefined,
              zIndex: 9999999,
              left: 0,
              width: 'calc(100vw - 20px)',
              backdropFilter: 'blur(10px)',
              WebkitBackdropFilter: 'blur(10px)',
            }}
          >
            <Grid container spacing={16} alignItems="center" justify="space-between">
              <Grid item>
                <Grid container spacing={16} alignItems="center">
                  <Grid item>
                    {icon}
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="caption"
                      style={{
                        color: 'white',
                      }}
                    >
                      {subtitle}
                    </Typography>
                    <Typography
                      variant="body2"
                      style={{
                        color: 'white',
                      }}
                    >
                      {body}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Button
                  size="small"
                  style={{
                    color: '#FFFFFF',
                    borderColor: '#FFFFFF',
                  }}
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                  onClick={() => {
                    if (cookie) {
                      cookies.set(cookie, true, { path: '/' });
                    }

                    if (callback) callback();
                    this.setState({ confirmed: true });
                  }
                }
                >
                  {action}
                </Button>
              </Grid>
            </Grid>
          </div>
        </Zoom>
      </ClickAwayListener>
    );
  }
}

export default withCookies(withStyles(styles)(TopMessage));
