import DateFnsUtils from "@date-io/date-fns";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import InputAdornment from "@material-ui/core/InputAdornment";
import MenuItem from "@material-ui/core/MenuItem";
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import RoomIcon from "@material-ui/icons/Room";
import TodayIcon from "@material-ui/icons/Today";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import * as Actions from "actions";
// components
import Page from "components/Page";
import "date-fns";
import { addObjectParamsToPath, queryParamsToObject } from "helpers";
import AssigmentDetail from "hooks/AssigmentDetail";
// hooks
import DriverList from "hooks/DriverList";
import RoundedTextField from "hooks/RoundedTextField";
import useLocalStorage from "hooks/useLocalStorage";
import isEqual from "lodash.isequal";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AREA_CHANNEL,
  GET_AREAS,
  GET_ASSIGNMENTS,
  GET_ASSIGNMENT_BY_ID,
} from "../../../constants";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function WrapperWorkflowPage({ history, location }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [storedNewMessages, setStoredNewMessages] = useLocalStorage(
    "newMessages",
    {}
  );

  const storedArea = useSelector((state) => state.storedArea);

  const [urlParams, setUrlParams] = useState({
    date: new Date().toString(),
    area: storedArea,
    ...queryParamsToObject(location.search),
  });

  const [driverList, setDriverList] = useState([]);
  const [filteredDriverList, setFilteredDriverList] = useState([]);

  const getAssignmentsReq = useSelector((state) => state.assignments)[
    GET_ASSIGNMENTS
  ];
  const getAssignmentByIdReq = useSelector((state) => state.assignments)[
    GET_ASSIGNMENT_BY_ID
  ];
  const getAreasReq = useSelector((state) => state.areas)[GET_AREAS];
  const areaChannel = useSelector((state) => state.centrifuge)[AREA_CHANNEL];

  console.log("URL PARAMS =======> ", location.search, urlParams);

  //============ CALLBACKS ============

  const handleNewMessage = useCallback(() => {
    if (areaChannel.data) {
      const newMessage = areaChannel.data.body;
      if (urlParams.id.toString() !== newMessage.assignmentID.toString()) {
        setDriverList((current) =>
          current.map((item) => {
            if (item.id === newMessage.assignmentID) {
              item.hasNewMessage = true;
            }
            return item;
          })
        );
      }
      dispatch(Actions.cleanState(AREA_CHANNEL));
    }
  }, [dispatch, urlParams, areaChannel]);

  const handleSearch = useCallback(
    (word) => {
      setFilteredDriverList(
        driverList.filter((driver) => {
          if (word) {
            return driver.userName.toLowerCase().includes(word.toLowerCase());
          }
          return true;
        })
      );
    },
    [driverList]
  );

  const handleUrlParams = useCallback(() => {
    setUrlParams((current) => {
      const newUrlParams = {
        area: current.area,
        date: current.date,
        ...queryParamsToObject(location.search),
      };

      if (
        newUrlParams.date !== current.date ||
        newUrlParams.area.toString() !== current.area.toString()
      ) {
        dispatch(Actions.getAssignments(newUrlParams.area, newUrlParams.date));
      }

      return newUrlParams;
    });
  }, [dispatch, location]);

  //============ FUNCTIONS ============

  const handleChangeArea = (area) => {
    if (handleFilterAssignments("area", area)) {
      dispatch(Actions.setStoredArea(area));
      dispatch(Actions.cleanState(GET_ASSIGNMENT_BY_ID));
    }
  };

  const handleChangeDate = (date) => {
    if (handleFilterAssignments("date", date)) {
      dispatch(Actions.cleanState(GET_ASSIGNMENT_BY_ID));
    }
  };

  const handleFilterAssignments = (filter, value) => {
    if (
      !urlParams[filter] ||
      value.toString() !== urlParams[filter].toString()
    ) {
      const params = queryParamsToObject(location.search);
      if (filter !== "id") {
        delete params.id;
      }
      console.log("LOCATION PARAMS ===> ", params);
      history.push(
        addObjectParamsToPath("/workflow", { ...params, [filter]: value })
      );
      return true;
    }
    return false;
  };

  //============ EFFECTS ============

  useEffect(() => {
    handleSearch();
  }, [handleSearch]);

  useEffect(() => {
    handleUrlParams();
  }, [handleUrlParams]);

  useEffect(() => {
    handleNewMessage();
  }, [handleNewMessage]);

  useEffect(() => {
    if (driverList && driverList.length) {
      const newData = driverList.reduce((acc, item) => {
        if (!acc[item.id]) {
          acc[item.id] = item.hasNewMessage;
        }
        return acc;
      }, {});
      if (!isEqual(storedNewMessages, newData)) {
        setStoredNewMessages(newData);
      }
    }
  }, [driverList, setStoredNewMessages, storedNewMessages]);

  useEffect(() => {
    if (getAreasReq && getAreasReq.success && getAreasReq.data.length) {
      setUrlParams((current) => {
        const area = current.area || getAreasReq.data[0].id;
        dispatch(Actions.getAssignments(area, current.date));
        return { ...current, area };
      });
    }
  }, [dispatch, getAreasReq]);

  useEffect(() => {
    if (getAssignmentByIdReq && getAssignmentByIdReq.success) {
      setDriverList((current) =>
        current.map((item) => {
          if (item.id === getAssignmentByIdReq.data.id) {
            item.hasNewMessage = false;
            item.resolved = getAssignmentByIdReq.data.resolved;
          }
          return item;
        })
      );
      // messageThreadButtonRef.current && messageThreadButtonRef.current.click();
    }
  }, [getAssignmentByIdReq]);

  useEffect(() => {
    if (getAssignmentsReq && getAssignmentsReq.success) {
      setDriverList(getAssignmentsReq.data);
      if (getAssignmentsReq.data.length) {
        setUrlParams((current) => {
          const id = current.id || getAssignmentsReq.data[0].id;
          dispatch(Actions.getAssignmentById(id));
          dispatch(Actions.getMessagesByAssignmentId(id));
          dispatch(Actions.getGpsUpdatesAssignmentId(id));
          return { ...current, id };
        });
      }
    }
    return () => {
      dispatch(Actions.cleanState(GET_ASSIGNMENT_BY_ID));
      dispatch(Actions.cleanState(GET_ASSIGNMENTS));
    };
  }, [dispatch, getAssignmentsReq]);

  //============ RENDER ============

  console.log(storedArea);

  return (
    <Page
      helmet="Workflow"
      noPadding
      fab={
        <Hidden lgUp>
          <DriverList
            list={filteredDriverList}
            activeItem={urlParams.id}
            onSearch={handleSearch}
            onSelect={(e, item) => handleFilterAssignments("id", item.id)}
            variant="floating"
          />
        </Hidden>
      }
    >
      <AppBar className={classes.appBar} position="static">
        <form className={classes.form} noValidate autoComplete="off">
          {getAreasReq && getAreasReq.success && getAreasReq.data.length && (
            <RoundedTextField
              select
              variant="outlined"
              placeholder="Search..."
              startIcon={<RoomIcon />}
              endIcon={<ExpandMoreIcon />}
              value={urlParams.area}
              color="blue"
              shade={400}
              textColor="white"
              onChange={(event) => handleChangeArea(event.target.value)}
            >
              {getAreasReq.data.map((area) => (
                <MenuItem value={area.id}>{area.name}</MenuItem>
              ))}
            </RoundedTextField>
          )}

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              autoOk
              variant="inline"
              format="MM/dd/yyyy"
              value={urlParams.date}
              onChange={(date) => handleChangeDate(date)}
              allowKeyboardControl={false}
              InputProps={{
                disableUnderline: true,
                classes: {
                  root: classes.datePicker,
                },
                startAdornment: (
                  <InputAdornment position="start">
                    {" "}
                    <TodayIcon />{" "}
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {" "}
                    <ExpandMoreIcon />{" "}
                  </InputAdornment>
                ),
              }}
            />
          </MuiPickersUtilsProvider>
        </form>
      </AppBar>
      <div className={classes.container}>
        <Hidden mdDown>
          <Box display="flex">
            <DriverList
              list={filteredDriverList}
              activeItem={urlParams.id || "-1"}
              onSearch={handleSearch}
              onSelect={(e, item) => handleFilterAssignments("id", item.id)}
            />
          </Box>
        </Hidden>
        <div className={classes.container}>
          <AssigmentDetail urlParams={urlParams} />
        </div>
      </div>
    </Page>
  );
}
