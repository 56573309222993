import { networkAction } from 'helpers/network/networkAction';

import {
  GET_INTERACTIONS,
  GET_INTERACTION_BY_ID,
  DELETE_INTERACTION,
  UPDATE_INTERACTION,
  CREATE_INTERACTION,
} from 'constants/interactions';

import * as Api from 'api';

export const getInteractions = () => async dispatch => networkAction(
  dispatch,
  GET_INTERACTIONS,
  Api.getInteractions,
  []
);

export const getInteractionById = (id) => async dispatch => networkAction(
  dispatch,
  GET_INTERACTION_BY_ID,
  Api.getInteractionById,
  [id]
);

export const deleteInteraction = (id) => async dispatch => networkAction(
  dispatch,
  DELETE_INTERACTION,
  Api.deleteInteraction,
  [id]
);

export const createInteraction = (body) => async dispatch => networkAction(
  dispatch,
  CREATE_INTERACTION,
  Api.createInteraction,
  [body]
);

export const updateInteraction = (id, body) => async dispatch => networkAction(
  dispatch,
  UPDATE_INTERACTION,
  Api.updateInteraction,
  [id, body]
);
