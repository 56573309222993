import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
// material-ui
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "components/AppBar";
// reactor
import AuthChecker from "components/AuthChecker";
import EnvMessage from "components/EnvMessage";
import ForgotPassword from "components/ForgotPassword";
import KeyboardEvents from "components/KeyboardEvents";
import LeftMenu from "components/LeftMenu";
import MessageDrawer from "components/MessageDrawer";
// components
import NotificationCenter from "components/NotificationCenter";
import ResetPassword from "components/ResetPassword";
import Signup from "components/Signup";
import Upload from "components/Upload";
import UrlActions from "components/UrlActions";
import {
  ENV_MESSAGE,
  GOOGLE_ANALYTICS_KEY,
  REACT_APP_ROOT_BACKGROUND
} from "config";
import { DEFAULT, SIGNIN } from "constants/routes";
import { redirectAccordingToRole } from "helpers/redirect";
import PropTypes from "prop-types";
import React from "react";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
// styles
import styles from "./styles";

class WrapperRootPage extends React.Component {
  static propTypes = {
    routes: PropTypes.array,
    history: PropTypes.object,
    theme: PropTypes.object,
    location: PropTypes.object,
    classes: PropTypes.object,

    // Reducers
    user: PropTypes.object,
    userApps: PropTypes.array,

    // Api
    getCurrentUser: PropTypes.func,
    signup: PropTypes.func,
    validateEmail: PropTypes.func,
    stopImpersonate: PropTypes.func,
    requestResetPassword: PropTypes.func,
    resetPassword: PropTypes.func,
    signout: PropTypes.func,

    // errors
    errors: PropTypes.object,

    // app
    storeResetPasswordToken: PropTypes.func,
    setDarkMode: PropTypes.func,
    app: PropTypes.object,
    init: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      mobileOpen: false,
      loading: true,
      alert: null,
    };

    if (GOOGLE_ANALYTICS_KEY) {
      ReactGA.initialize(GOOGLE_ANALYTICS_KEY);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location !== prevProps.location) {
      if (GOOGLE_ANALYTICS_KEY) {
        ReactGA.pageview(window.location.pathname + window.location.search);
      }
    }
  }

  handleDrawerToggle = () => {
    this.setState((prevState) => ({ mobileOpen: !prevState.mobileOpen }));
  };

  handleClick = () => {
    this.setState((prevState) => ({ open: !prevState.open }));
  };

  injectRoutes() {
    const { routes } = this.props;

    return (
      <div style={{ flexGrow: 1, display: "flex", position: "relative" }}>
        <Switch>
          {routes.map((route) =>
            route.path === DEFAULT ? (
              <Route key={route.path} component={route.component} />
            ) : (
              <Route
                key={route.path}
                path={route.path}
                component={route.component}
              />
            )
          )}
        </Switch>
      </div>
    );
  }

  render() {
    const {
      classes,
      location,
      routes,
      history,
      getCurrentUser,
      errors,
      signup,
      validateEmail,
      requestResetPassword,
      storeResetPasswordToken,
      app,
      resetPassword,
      user,
      setDarkMode,
      signout,
      userApps,
      init,
      stopImpersonate,
    } = this.props;

    const { loading, alert, mobileOpen } = this.state;

    const route = routes.find((r) => r.path === location.pathname);

    return (
      <div>
        <AuthChecker
          routes={routes}
          history={history}
          location={location}
          user={user}
          signinRoute={SIGNIN}
          redirectAccordingToRole={redirectAccordingToRole}
          getCurrentUser={getCurrentUser}
          init={init}
          onReady={() => {
            this.setState({ loading: false });
          }}
        >
          <NotificationCenter errors={errors}>
            <KeyboardEvents>
              <UrlActions
                location={location}
                history={history}
                validateEmail={validateEmail}
                storeResetPasswordToken={storeResetPasswordToken}
              >
                <Signup
                  history={history}
                  signup={signup}
                  validateEmail={validateEmail}
                >
                  <ForgotPassword
                    history={history}
                    requestResetPassword={requestResetPassword}
                  >
                    <ResetPassword
                      token={app.resetPasswordToken}
                      resetPassword={resetPassword}
                      storeResetPasswordToken={storeResetPasswordToken}
                    >
                      <Upload>
                        {loading ? (
                          <Grid
                            container
                            className={classes.loading}
                            alignContent="center"
                            alignItems="center"
                          >
                            <Grid
                              item
                              xs={12}
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <CircularProgress
                                style={{ color: "#ffffff" }}
                              />
                            </Grid>
                          </Grid>
                        ) : (
                          <MessageDrawer
                            background={REACT_APP_ROOT_BACKGROUND}
                          >
                            <EnvMessage envMessage={ENV_MESSAGE} />
                            <div
                              style={{
                                display: "flex",
                                flexGrow: 1,
                              }}
                            >
                              {route && route.withSidebar && (
                                <LeftMenu
                                  routes={routes}
                                  handleDrawerToggle={this.handleDrawerToggle.bind(
                                    this
                                  )}
                                  mobileOpen={mobileOpen}
                                  history={history}
                                  user={user}
                                  location={location}
                                />
                              )}
                              <div
                                style={{
                                  flexGrow: 1,
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                {route && route.withAppBar && (
                                  <AppBar
                                    onMenuOpen={this.handleDrawerToggle.bind(
                                      this
                                    )}
                                    user={user}
                                    userApps={userApps}
                                    history={history}
                                    app={app}
                                    setDarkMode={setDarkMode}
                                    stopImpersonate={stopImpersonate}
                                    signout={signout}
                                  />
                                )}
                                {this.injectRoutes()}
                                {alert}
                              </div>
                            </div>
                          </MessageDrawer>
                        )}
                      </Upload>
                    </ResetPassword>
                  </ForgotPassword>
                </Signup>
              </UrlActions>
            </KeyboardEvents>
            
          </NotificationCenter>
        </AuthChecker>
      </div>
    );
  }
}

export default
  withRouter(
    connect()(withStyles(styles, { withTheme: true })(WrapperRootPage))
  );
