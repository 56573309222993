import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
// material-ui
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { AddCircle, MoreVert, Redo } from "@material-ui/icons";
import CheckIcon from "@material-ui/icons/Check";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem as MuiTimelineItem,
  TimelineSeparator,
} from "@material-ui/lab";
import { pluralize } from "helpers";
import ColoredChip from "hooks/ColoredChip";
import ColoredTextTypography from "hooks/ColoredTextTypography";
import EmptySpot from "hooks/EmptySpot";
import TaskKindIcon from "hooks/TaskKindIcon";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

const NO_TIME_SET = "0001-01-01T00:00:00Z";

const TimelineItem = withStyles({
  missingOppositeContent: {
    "&:before": {
      display: "none",
    },
  },
})(MuiTimelineItem);

export default function Stop({
  stop,
  currentStop,
  currentStopOrder,
  completed,
  onSelect,
  onSkip,
  openPopover,
  collapsed = false,
}) {
  const classes = useStyles({
    skipped: stop.skipped,
    completed,
    active: currentStop && currentStop.id === stop.id,
  });

  const [expanded, setExpanded] = useState(!collapsed);
  const [menuEl, setMenuEl] = useState(null);

  useEffect(() => {
    setExpanded(!collapsed);
  }, [collapsed]);

  const handleExpandClick = (event) => {
    event.stopPropagation();
    setExpanded(!expanded);
  };

  const getFormattedTime = () => {
    const tf = moment.utc(stop.timeFrom).format("HH:mm");
    const tt = moment.utc(stop.timeTo).format("HH:mm");
    if (tf === tt) {
      return tf;
    }
    return `${tf} - ${tt}`;
  };

  const getTruckIcon = () => {
    if (stop.needsTrailer) {
      return (
        <Tooltip title="Needs trailer" aria-label="Needs trailer">
          <WarningRoundedIcon
            fontSize="large"
            color="error"
            onClick={(event) => onSelect(event, stop)}
          />
        </Tooltip>
      );
    }
    if (stop.stopOrder === currentStopOrder) {
      return <LocalShippingIcon fontSize="large" />;
    }
    return <EmptySpot />;
  };

  const getEtaChip = () => {
    const minutesOfDay = (m) => {
      return m.minutes() + m.hours() * 60;
    };
    if (stop.etaUTC) {
      const tt = moment.utc(stop.timeTo);
      const label = moment.tz(stop.etaUTC, stop.location.timezone);
      return (
        <ColoredChip
          className={classes.chip}
          color={
            minutesOfDay(tt) > minutesOfDay(label) || completed
              ? "green"
              : "red"
          }
          onClick={handleExpandClick}
          mb={2}
          label={`ETA: ${label.format("HH:mm")}`}
          size="small"
        />
      );
    }
  };

  return (
    <Box
      display="flex"
      className={classes.root}
      onClick={(event) => onSelect(event, stop)}
    >
      <Box
        ml={2}
        display="flex"
        alignItems={stop.atLeastOneTaskComplete ? "flex-end" : "flex-start"}
        mb={!completed && stop.atLeastOneTaskComplete && 7}
      >
        <div
          style={{
            width: 35,
            height: 70 + stop.tasks.length * 15,
          }}
        >
          <Typography
            variant="caption"
            color="textSecondary"
            className={classes.stopID}
          >
            Stop
            <br />#{stop.id}
            {!stop.skipped ? (
              <div>
                {stop.tasks.map((t) => (
                  <TaskKindIcon kind={t.kind} />
                ))}
              </div>
            ) : (
              []
            )}
          </Typography>
          {stop.skipped ? (
            <Typography className={classes.skipped}>SKIPPED</Typography>
          ) : (
            []
          )}
          <div className={classes.truck}>{getTruckIcon()}</div>
        </div>
        <IconButton
          className={classes.more}
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setMenuEl(e.currentTarget);
          }}
        >
          <MoreVert />
        </IconButton>
        <Menu
          onClose={(e) => setMenuEl(null)}
          anchorEl={menuEl}
          open={Boolean(menuEl)}
        >
          <MenuItem onClick={onSkip}>
            <ListItemIcon>
              <Redo fontSize="small" />
            </ListItemIcon>
            {stop.skipped ? "Unskip" : "Skip"} this Stop
          </MenuItem>
          {!completed && (
            <MenuItem
              onClick={(...args) => {
                setMenuEl(null);
                openPopover(...args);
              }}
            >
              <ListItemIcon>
                <AddCircle fontSize="small" />
              </ListItemIcon>
              Add Stop
            </MenuItem>
          )}
        </Menu>
      </Box>
      <Timeline className={classes.timeLine}>
        <TimelineItem className={classes.item}>
          <TimelineSeparator>
            <TimelineDot className={classes.dot} onClick={handleExpandClick}>
              {completed && <CheckIcon className={classes.dotIcon} />}
            </TimelineDot>
            <TimelineConnector
              className={classes.line}
              style={{ height: 70 + stop.tasks.length * 15 }}
            />
          </TimelineSeparator>
          <TimelineContent className={classes.content}>
            <Box display="flex">
              {stop.timeFrom !== NO_TIME_SET && (
                <ColoredChip
                  mr={1}
                  className={classes.chip}
                  color={completed ? "green" : "black"}
                  onClick={handleExpandClick}
                  mb={2}
                  label={getFormattedTime()}
                  size="small"
                />
              )}
              {getEtaChip()}
            </Box>
            {completed && !expanded && (
              <ColoredTextTypography color="green">
                <Box fontWeight="fontWeightBold">
                  {stop.location.name} - {stop.location.city},{" "}
                  {stop.location.state}
                </Box>
              </ColoredTextTypography>
            )}
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <Box mb={2} mt={stop.timeFrom === NO_TIME_SET && 5}>
                <ColoredTextTypography
                  component="div"
                  color={completed ? "green" : "grey"}
                  shade={completed ? 500 : 900}
                >
                  <Box fontWeight="fontWeightBold">
                    {stop.location.name} - {stop.location.city},{" "}
                    {stop.location.state}
                  </Box>
                </ColoredTextTypography>
                <ColoredTextTypography color={completed ? "green" : "grey"}>
                  {stop.location.address} {stop.location.zip}{" "}
                  {stop.location.state}
                </ColoredTextTypography>
              </Box>
              <Box style={{ paddingBottom: 20 }}>
                {!completed && (
                  <ColoredTextTypography color="blue">
                    {stop.atLeastOneTaskComplete
                      ? `${stop.totalUncompletedTasks}/`
                      : ""}
                    {stop.totalTasks}{" "}
                    {pluralize("task", stop.totalUncompletedTasks)} to complete
                  </ColoredTextTypography>
                )}
                {stop.skipped && stop.skippedReason ? (
                  <div className={classes.skip}>
                    <Typography variant="body2">
                      <b>Skipped Reason</b>: {stop.skippedReason}
                    </Typography>
                  </div>
                ) : (
                  []
                )}
              </Box>
            </Collapse>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    </Box>
  );
}
