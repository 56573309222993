import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import SockJS from 'sockjs-client';
import store from './store';
import './index.css';
import App from './routes/root/container/Root';
import { unregister } from './registerServiceWorker';

import { CentrifugeProvider } from 'contexts';

window.SockJS = SockJS;

ReactDOM.render(
  <Provider store={store()}>
    <CentrifugeProvider><App /></CentrifugeProvider>
  </Provider>,
  document.getElementById('root'),
);
unregister();
