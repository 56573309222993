import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import classNames from "classnames";
import RoundedChar from "hooks/RoundedChar";
import moment from "moment";
import React, { useEffect } from "react";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function Job({ children, job, collapsed = false }) {
  const [expanded, setExpanded] = React.useState(!collapsed);

  const [messages, setMessages] = React.useState([]);

  const classes = useStyles(styles);

  useEffect(() => {
    setExpanded(!collapsed);
  }, [collapsed]);

  const handleExpandClick = () => {
    const d = new Date();
    d.setDate(Math.random() * 10);
    setMessages([
      ...messages,
      { d, m: `${Math.random() * 10000}`, t: expanded ? "sender" : "receiver" },
    ]);
    setExpanded(!expanded);
  };

  return (
    <Box mb={3}>
      <Box className={classes.content}>
        <RoundedChar content={job.jobOrder} />
        <Box ml={1}>
          <Box fontSize="h6.fontSize" fontWeight="fontWeightBold">
            Job #{job.refID}
          </Box>
          <Box fontSize="body2.fontSize">
            {moment.utc(job.startDate).format("dddd MMM Do YYYY")}
          </Box>
        </Box>
        <IconButton
          className={classNames(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </Box>
  );
}
