import orange from '@material-ui/core/colors/orange';

export default theme => ({
  content: {
    position: 'relative',
    borderRadius: 4
  },
  icon: {
    color: orange[500],
    fontSize: 50
  }
});
