import { REQUEST_INITIAL_STATE, GET_AREAS } from '../constants';
import { handleLoading, handleSuccess, handleError } from 'helpers/reducer';

const initialState = {
  [GET_AREAS]: REQUEST_INITIAL_STATE 
}

export default (state = initialState, {type, payload}) => {
    switch (type) {

      case `${GET_AREAS}_LOADING`:
        return handleLoading({state, action: GET_AREAS});
      case `${GET_AREAS}_SUCCESS`:
        return handleSuccess({state, action: GET_AREAS, payload});
      case `${GET_AREAS}_ERROR`:
        return handleError({state, action: GET_AREAS, payload});
      default:
        return state;

    }
};
