import React from 'react';
import PropTypes from 'prop-types';

// custom
import FormResetPassword from 'components/FormResetPassword';
import FormPersonalInfo from 'components/FormPersonalInfo';

// material-ui
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';

// reactor
import App from 'components/App';
import Page from 'components/Page';

// styles
import styles from './styles';

class WrapperMyAccountPage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    user: PropTypes.object,
    updateUser: PropTypes.func,
    refresh: PropTypes.func,
    updateUserPassword: PropTypes.func,

    userApps: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
    };
  }

  getStepContent() {
    const {
      updateUserPassword,
      updateUser,
      user,
      classes,
      refresh,
      userApps,
    } = this.props;

    const { index } = this.state;

    switch (index) {
      case 0:
        return (
          <Grid container spacing={24}>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom noWrap>Your Personal Data</Typography>
              <Typography>
                You can here update your personal information such as your first and last name,
                your email and change your password
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={24}>
                <Grid item xs={12}>
                  <FormPersonalInfo
                    refresh={refresh}
                    user={user}
                    updateUser={updateUser}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormResetPassword
                    user={user}
                    updateUserPassword={updateUserPassword}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Paper className={classes.padding}>
                    <Typography variant="h6" gutterBottom>
                      Default App
                    </Typography>
                    <Typography variant="caption" gutterBottom>
                      Automaticaly launch this app after a login
                    </Typography>
                    <FormControl className={classes.formControl} fullWidth>
                      <InputLabel htmlFor="default-app">Default App</InputLabel>
                      <Select
                        inputProps={{
                          name: 'Default App<',
                          id: 'default-app',
                        }}
                        value={user.defaultAppID}
                        onChange={async (e) => {
                          await updateUser(
                            user.id,
                            {
                              defaultAppID: e.target.value,
                            },
                          );
                          refresh();
                        }}
                      >
                        {
                          userApps.map(a => (
                            <MenuItem key={`app_${a.id}`} value={a.id}>
                              <Chip
                                avatar={(
                                  <Avatar style={{ background: '#424242' }}>
                                    <App {...a} size={20} />
                                  </Avatar>
                                )}
                                key={`chipApp_${a.id}`}
                                className={classes.chip}
                                style={{
                                  color: 'white',
                                  background: '#424242',
                                }}
                                label={`${a.name}`}
                              />
                            </MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper className={classes.padding}>
                    <Typography variant="h6" gutterBottom>
                      Other
                    </Typography>
                    <Grid container>
                      <Typography>
                        Uuid
                      </Typography>
                      <Grid
                        item
                        xs={12}
                      >
                        <Typography variant="caption" gutterBottom>
                          {user.uuid}
                        </Typography>
                      </Grid>
                      <Typography>
                        Created At
                      </Typography>
                      <Grid
                        item
                        xs={12}
                      >
                        <Typography variant="caption" gutterBottom>
                          {user.createdAt}
                        </Typography>
                      </Grid>
                      <Typography>
                        Updated At
                      </Typography>
                      <Grid
                        item
                        xs={12}
                      >
                        <Typography variant="caption" gutterBottom>
                          {user.updatedAt}
                        </Typography>
                      </Grid>
                      <Typography>
                        Id
                      </Typography>
                      <Grid
                        item
                        xs={12}
                      >
                        <Typography variant="caption" gutterBottom>
                          {user.id}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper className={classes.padding}>
                    <Typography variant="h6" gutterBottom>
                      Services & Roles
                    </Typography>
                    {
                      Object.keys(user.services).map(k => (
                        <div
                          key={`service_${k}`}
                        >
                          <Typography>
                            {k}
                          </Typography>
                          {
                            user.services[k].map(r => (
                              <Chip
                                key={`role_${k}_${r}`}
                                label={r}
                                className={classes.chip}
                              />
                            ))
                          }
                        </div>
                      ))
                    }
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      default:
        return (
          <Typography variant="h6">You have gone too far, Sonny Jim !</Typography>
        );
    }
  }

  render() {
    const {
      classes,
      history,
    } = this.props;

    const {
      index,
    } = this.state;

    return (
      <Page
        helmet="My Account"
        loadingMessage={'Loading Information'}
        tabs={(
          <React.Fragment>
            <Tabs
              value={index}
              onChange={(e, v) => {
                this.setState({
                  index: v,
                });
                history.push(`?index=${v}`);
              }}

              indicatorColor="primary"
              textColor="primary"
              scrollable
              scrollButtons="auto"
              classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
            >
              <Tab
                label="My Account"
                classes={{
                  root: classes.tabRoot,
                  selected: classes.tabSelected,
                }}
              />
            </Tabs>
          </React.Fragment>
        )}
      >
        {this.getStepContent()}
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperMyAccountPage);
