export const GET_ASSIGNMENTS = "GET_ASSIGNMENTS";
export const GET_ASSIGNMENT_BY_ID = "GET_ASSIGNMENT_BY_ID";
export const UPDATE_ASSIGNMENT = "UPDATE_ASSIGNMENT";
export const DELETE_ASSIGNMENT = "DELETE_ASSIGNMENT";
export const CREATE_ASSIGNMENT = "CREATE_ASSIGNMENT";

export const SET_SEARCH_DRIVER = "SET_SEARCH_DRIVER";
export const DISMISS_ISSUE = "DISMISS_ISSUE";

export const SEARCH_LOCATION = "SEARCH_LOCATION";
export const GET_NODES = "GET_NODES";
export const CREATE_TASK = "CREATE_TASK";
export const CREATE_STOP = "CREATE_STOP";
export const CREATE_EMPTY_TRAILER = "CREATE_EMPTY_TRAILER";
export const GET_GPS_UPDATE_BY_ASSIGNMENT_ID =
  "GET_GPS_UPDATE_BY_ASSIGNMENT_ID";
export const SYNC_DEVICE = "SYNC_DEVICE";
export const COMMAND = "COMMAND";
