import { networkAction } from 'helpers/network/networkAction';

import {
  GET_AREAS,
} from 'constants/areas';

import * as Api from 'api';

export const getAreas = () => async dispatch => networkAction(
  dispatch,
  GET_AREAS,
  Api.getAreas,
  [],
);
