import { networkAction } from 'helpers/network/networkAction';

import {
  GET_TRIP_REPORTS,
  GET_TRIP_HISTORICAL,
} from 'constants/tripReports';

import * as Api from 'api';

export const getTripReports = (filters, id) => async dispatch => networkAction(
  dispatch,
  GET_TRIP_REPORTS,
  Api.getTripReports,
  [filters, id],
);

export const getTripHistorical = filters => async dispatch => networkAction(
  dispatch,
  GET_TRIP_HISTORICAL,
  Api.getTripHistorical,
  [filters],
);
