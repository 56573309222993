export default theme => ({
  dayWrapper: {
    position: 'relative',
  },
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: '0 2px',
    color: 'inherit',
  },
  customDayHighlight: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: '2px',
    right: '2px',
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '50%',
  },
  disabled: {
    opacity: 0.4,
  },
  nonCurrentMonthDay: {
    opacity: 0,
  },
  highlightNonCurrentMonthDay: {
    color: '#676767',
  },
  highlight: {
    background: `${theme.palette.primary.main} !important`,
    color: `${theme.palette.common.white} !important`,
    borderRadius: 20,
  },
  holiday: {
    background: `#e0e0e0`,
    color: `${theme.palette.common.black}`,
    borderRadius: 20,
  },
  today: {
    background: '#e0e0e0',
    color: theme.palette.common.black,
    borderRadius: 20,
  },
  firstHighlight: {
    extend: 'highlight',
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  },
  endHighlight: {
    extend: 'highlight',
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  },
});
