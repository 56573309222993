import React from 'react';
import classNames from 'classnames';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';

// styles
import styles from './styles';

const useStyles = makeStyles(styles);

export default function ColoredChip({color, shade, icon, label, onClick, className, size = "medium", ...other}) {
  const classes = useStyles({color, shade});

  return <Box {...other}>
    <Chip clickable onClick={onClick} className={classNames(className, classes.chip)} icon={icon} label={label} size={size}/>
  </Box>;
}



