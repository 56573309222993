import {
  ERROR_NOTIFICATION,
  NEW_MESSAGE
} from '../constants';

import {
  REACT_APP_SUPPORT_EMAIL,
} from 'config';
import { truncate, getKey, queryParamsToObject } from 'helpers';

export default (state = {}, action) => {
  const { payload } = action;
  if (action.type === ERROR_NOTIFICATION) {
    let isError = false;
    let isWarning = false;

    if (payload === undefined) {
      return state;
    }

    if (payload.status === 401) {
      return state;
    }

    // 4xx errors are warning (client side)
    if ((payload.status <= 499) && (payload.status >= 400)) {
      isWarning = true;
    }

    // 5xx errors are erros (server side)
    if ((payload.status <= 599) && (payload.status >= 500)) {
      isError = true;
    }

    const error = {
      notification: {
        message: {
          title: payload.description,
          subtitle: payload.resolution,
          body: `${payload.status} ${payload.errorCode}`,
          error: isError,
          warning: isWarning,
          timestamp: new Date().getTime(),
        },
        actions: {
          confirm: {
            label: payload.action,
            level: isError ? 'error' : 'warning',
            callback: () => {
              window.location.href = `mailto:${REACT_APP_SUPPORT_EMAIL}?subject=[Portal] ${new Date().getTime()} - ${payload.status} ${payload.errorCode}&body=${payload.function}%0D%0A%0D%0AStack:${payload.stack.map(s => `%0D%0A${s}`)}%0D%0A%0D%0ABrowser Info:%0D%0A${window.navigator.appCodeName}%0D%0A${window.navigator.appName}%0D%0A${window.navigator.appVersion}`; //eslint-disable-line
            },
          },
        },
      },
    };

    return error;
  }

  if (action.type === NEW_MESSAGE) {
    const {id} = queryParamsToObject(window.location.search);
    const currentUser = payload.message.currentUser;
    const message = payload.message.data.body;
    if (id && id.toString() === message.assignmentID.toString()) {
      return state;
    }
    const error = {
      notification: {
        message: {
          title: 'New Message',
          subtitle: message.displayName,
          body: truncate(message.content, 30),
          success: true,
          timestamp: new Date().getTime(),
        },
        actions: {
          cancel: {
            label: 'Close',
            level: 'default',
            callback: () => console.log('Callback'),
          },
          confirm: {
            label: 'GO',
            level: 'success',
            callback: () => {
              if (currentUser.uuid !== message.senderUUID) {
                const area = getKey('area', '1');
                window.location.href = `${window.location.origin}/workflow?id=${message.assignmentID}&area=${area}`;
              }
            }
          }
        }
      }
    };

    return error;
  }

  return state;
};
