import React from 'react';
// import PropTypes from 'prop-types';

import Paper from "@material-ui/core/Paper";

// material-ui
import { makeStyles } from '@material-ui/core/styles';

// styles
import styles from './styles';

const useStyles = makeStyles(styles);

export default function AssigmentDetailDynamicContent({children, arrowposition}) {
  // const [ state, setState ] = React.useState(initialValue);
  const classes = useStyles({arrowposition});

  return <div className={classes.container}>
    <Paper className={classes.paper} >
      {children}
    </Paper>
  </div>;
}

