import { Divider, Icon } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Popover from "@material-ui/core/Popover";
import Select from "@material-ui/core/Select";
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import * as Actions from "actions";
import Loading from "components/Loading";
import { REACT_APP_API, REACT_APP_API_PREFIX } from "config";
import { useCentrifuge } from "contexts";
import { metersToMiles, secondsToHMS } from "helpers";
import AddJob from "hooks/AddJob";
import AssigmentDetailDynamicContent from "hooks/AssigmentDetailDynamicContent";
import AssignmentPreview from "hooks/AssignmentPreview";
import ColoredButton from "hooks/ColoredButton";
import ColoredTextTypography from "hooks/ColoredTextTypography";
import GPSUpdateList from "hooks/GPSUpdateList";
import IssueList from "hooks/IssueList";
import Job from "hooks/Job";
import MessageThread from "hooks/MessageThread";
import SearchLocationField from "hooks/SearchLocationField";
import Stop from "hooks/Stop";
import TaskList from "hooks/TaskList";
import pick from "lodash.pick";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AREA_CHANNEL,
  CREATE_STOP,
  CREATE_TASK,
  GET_ASSIGNMENTS,
  GET_ASSIGNMENT_BY_ID,
  GET_GPS_UPDATE_BY_ASSIGNMENT_ID,
  GET_MESSAGES_BY_ASSIGNMENT_ID,
  UPDATE_ASSIGNMENT,
} from "../../constants";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

const MESSAGES = 1;
const ISSUES = 2;
const TASK = 3;
const GPS_UPDATES = 4;

const getInfoWindowString = (element) => `<div>
  <div style="font-size: 16px;">
    ${element.deviceName} - ${element.id}
  </div>
  <div style="font-size: 14px; color: grey;">
    ${moment.utc(element.timestamp).format("MMM DD YYYY HH:mm A")}
  </div><br/>
  <div style="font-size: 14px; color: grey;">
    <strong>Created:</strong> ${moment
      .utc(element.createdAt)
      .format("MMM DD YYYY HH:mm A")}
  </div>
</div>`;

export default function AssigmentDetail({ urlParams }) {
  const classes = useStyles(styles);
  const dispatch = useDispatch();
  const centrifugeContext = useCentrifuge();

  const [location, setLocation] = useState(null);
  const [kind, setKind] = React.useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverContent, setPopoverContent] = useState(CREATE_STOP);
  const [issuesList, setIssuesList] = useState([]);
  // const [currentStop, setCurrentStop] = useState(null);
  const [selection, setSelection] = useState({
    jobIndex: null,
    stopIndex: null,
  });
  const [newStop, setNewStop] = useState(null);
  const [newTask, setNewTask] = useState(null);
  const [gpsUpdates, setGpsUpdates] = useState([]);
  const [routeCalculation, setRouteCalculation] = useState();
  const [showNewMessageBadge, setShowNewMessageBadge] = useState(false);
  const [currentDynamicContent, setCurrentDynamicContent] = useState(MESSAGES);
  const [showAllMarkers, setShowAllMarkers] = useState(false);
  const [satelliteMapType, setSatelliteMapType] = useState(false);
  const [anyOrder, setAnyOrder] = useState(false);
  const [resolved, setResolved] = useState(true);

  const currentUser = useSelector((state) => state.user);
  const chatReducerState = useSelector((state) => state.chat);
  const areaChannel = useSelector((state) => state.centrifuge)[AREA_CHANNEL];
  const getAssignmentsReq = useSelector((state) => state.assignments)[
    GET_ASSIGNMENTS
  ];
  const updateAssignmentReq = useSelector((state) => state.assignments)[
    UPDATE_ASSIGNMENT
  ];
  const getAssignmentByIdReq = useSelector((state) => state.assignments)[
    GET_ASSIGNMENT_BY_ID
  ];
  const getMessagesByAssigmentIdReq = useSelector((state) => state.assignments)[
    GET_MESSAGES_BY_ASSIGNMENT_ID
  ];
  const getGpsUpdatesByAssigmentIdReq = useSelector(
    (state) => state.assignments
  )[GET_GPS_UPDATE_BY_ASSIGNMENT_ID];
  const createStopReq = useSelector((state) => state.assignments)[CREATE_STOP];
  const createTaskReq = useSelector((state) => state.assignments)[CREATE_TASK];

  const params = useMemo(() => {
    return urlParams;
  }, [urlParams]);

  const handleRouteCalculation = (response, calculation) => {
    const duration = secondsToHMS(calculation.duration);
    const hours = duration.hours > 0 ? `${duration.hours}h` : "";
    const minutes =
      duration.hours > 0 ? duration.minutes : `${duration.minutes}m`;
    setRouteCalculation({
      distance: metersToMiles(calculation.distance),
      duration: `${hours}${minutes}`,
    });
  };

  const handleOpenPopover = (event, content, stop) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setPopoverContent(content);
    if (content === CREATE_STOP) {
      const newStop = pick(stop, ["assignmentID", "jobID", "stopOrder"]);
      newStop.stopOrder += 1;
      setNewStop(newStop);
    }
    if (content === CREATE_TASK) {
      setNewTask({ stopID: getCurrentStop()?.id });
    }
  };

  const toggleShowAllMarkers = () => setShowAllMarkers((prev) => !prev);
  const toggleSatelliteMapType = () => setSatelliteMapType((prev) => !prev);

  const toggleAnyOrder = () => {
    dispatch(Actions.updateAssignment(urlParams.id, { anyOrder: !anyOrder }));
    setAnyOrder((prev) => !prev);
  };

  const handleClose = () => setAnchorEl(null);

  const handleOnSelectStop = (selection) => {
    setSelection(selection);
    if (getCurrentStop(selection)?.tasks.length ?? false) {
      setCurrentDynamicContent(TASK);
    }
  };

  const getCurrentJob = (s) => {
    const _selection = s || selection;
    if (_selection.jobIndex === undefined) return;
    return getAssignmentByIdReq.data.jobs[_selection.jobIndex];
  };

  const getCurrentStop = (s) => {
    const _selection = s || selection;
    if (_selection.stopIndex === undefined) return;
    const currentJob = getCurrentJob();
    if (!currentJob) return;
    return currentJob.stops[_selection.stopIndex];
  };

  const handleOnCreateStop = (event) => {
    const stop = { ...newStop, locationRefID: location.id };
    dispatch(Actions.createStop(stop));
  };

  const handleOnCreateTask = (event, kind) => {
    const task = { ...newTask, kind };
    dispatch(Actions.createTask(task));
  };

  const handleCreateMessage = (content) => {
    if (getAssignmentByIdReq && getAssignmentByIdReq.success) {
      dispatch(
        Actions.createMessage(
          content,
          getAssignmentByIdReq.data.id,
          currentUser
        )
      );
    }
  };

  const handleSyncDevice = async () => {
    await dispatch(Actions.syncDevice(urlParams.id));
    alert("Updates have been sent to the driver's device.");
  };

  const handlePingDevice = async () => {
    await dispatch(Actions.command(urlParams.id, { command: "ping" }));
    alert(
      "Ping notification has been sent to the driver's device. You should get a new GPSUpdate shortly."
    );
  };

  const handleDiagnosis = async () => {
    await dispatch(Actions.command(urlParams.id, { command: "diagnosis" }));
    alert(
      "Ping notification has been sent to the driver's device. Visit Sentry to see the result."
    );
  };

  const handleDismiss = (event, issue) => {
    dispatch(Actions.dismissIssue(issue.id));
    handleFetchAssignmentById();
  };

  const handleSetMessageThreadContent = (event) => {
    setShowNewMessageBadge(false);
    centrifugeContext.connect();
    handleChangeDynamicContent(event, MESSAGES);
  };

  const handleViewGPSUpdates = (event) => {
    handleChangeDynamicContent(event, GPS_UPDATES);
  };

  const handleChangeDynamicContent = (event, content) => {
    setCurrentDynamicContent(content);

    setSelection((s) => ({ ...s, stopIndex: null }));
  };

  const handleOnSkip = (stop) => () => {
    dispatch(Actions.updateStop(stop.id, { skipped: !stop.skipped })).then(
      () => {
        dispatch(Actions.getAssignmentById(urlParams.id));
      }
    );
  };

  const handleCreateJob = (newJob) => {
    // console.log({ ...newJob, assignmentID: urlParams.id });
    dispatch(
      Actions.createEmptyTrailer({
        ...newJob,
        assignmentID: Number(urlParams.id),
      })
    ).then(() => {
      dispatch(Actions.getAssignmentById(urlParams.id));
    });
  };

  const handleNewMessage = useCallback(() => {
    if (areaChannel.data) {
      const newMessage = areaChannel.data.body;
      if (urlParams.id.toString() === newMessage.assignmentID.toString()) {
        if (currentDynamicContent !== MESSAGES) {
          setShowNewMessageBadge(true);
        }
      }
    }
  }, [areaChannel, currentDynamicContent, urlParams.id]);

  const handleFetchAssignmentById = useCallback(
    (reload) => {
      if (
        (getAssignmentByIdReq &&
          getAssignmentByIdReq.success &&
          urlParams.id.toString() !==
            getAssignmentByIdReq.data.id.toString()) ||
        reload
      ) {
        dispatch(Actions.getAssignmentById(urlParams.id));
        dispatch(Actions.getMessagesByAssignmentId(urlParams.id));
        dispatch(Actions.getGpsUpdatesAssignmentId(urlParams.id));
      }
    },
    [dispatch, urlParams.id, getAssignmentByIdReq]
  );

  useEffect(() => {
    handleNewMessage();
  }, [handleNewMessage]);

  useEffect(() => {
    handleFetchAssignmentById();
  }, [handleFetchAssignmentById]);

  useEffect(() => {
    setCurrentDynamicContent(MESSAGES);
    setSelection((s) => ({ ...s, stopIndex: null }));
  }, [params]);

  useEffect(() => {
    if (getAssignmentByIdReq && getAssignmentByIdReq.success) {
      if (
        getAssignmentByIdReq.data.issues &&
        getAssignmentByIdReq.data.issues.length
      ) {
        setResolved(getAssignmentByIdReq.data.resolved);
        setAnyOrder(getAssignmentByIdReq.data.anyOrder);
        setIssuesList(getAssignmentByIdReq.data.issues);
      }
    }
  }, [getAssignmentByIdReq]);

  useEffect(() => {
    if (
      getGpsUpdatesByAssigmentIdReq &&
      getGpsUpdatesByAssigmentIdReq.success
    ) {
      if (getGpsUpdatesByAssigmentIdReq.data) {
        setGpsUpdates(getGpsUpdatesByAssigmentIdReq.data);
      }
    }
  }, [getGpsUpdatesByAssigmentIdReq]);

  useEffect(() => {
    if (createStopReq && createStopReq.success) {
      handleFetchAssignmentById(true);
      dispatch(Actions.cleanState(CREATE_STOP));
      handleClose();
    }
  }, [dispatch, handleFetchAssignmentById, createStopReq]);

  useEffect(() => {
    if (createTaskReq && createTaskReq.success) {
      handleFetchAssignmentById(true);
      dispatch(Actions.cleanState(CREATE_TASK));
      handleClose();
    }
  }, [dispatch, handleFetchAssignmentById, createTaskReq]);

  const handleResolveChanged = () => {
    setResolved((i) => !i);
    dispatch(
      Actions.updateAssignment(urlParams.id, {
        resolved: !resolved,
      })
    ).then(() => {
      dispatch(Actions.getAssignmentById(urlParams.id));
    });
  };

  const open = Boolean(anchorEl);
  const id = open ? "new-stop-popover" : undefined;

  return (
    <>
      {getAssignmentsReq.loading ||
      (getAssignmentByIdReq && getAssignmentByIdReq.loading) ||
      (getGpsUpdatesByAssigmentIdReq &&
        getGpsUpdatesByAssigmentIdReq.loading) ? (
        <Loading background="none" />
      ) : (
        <>
          <div className={classes.content}>
            <Box pl={4} p={2}>
              {getAssignmentByIdReq && getAssignmentByIdReq.success && (
                <>
                  <Box
                    display="flex"
                    alignItems="flex-start"
                    justifyContent="space-between"
                  >
                    <Box>
                      <Box fontWeight="fontWeightBold" fontSize="h5.fontSize">
                        {getAssignmentByIdReq.data.userName}
                      </Box>
                      <Typography variant="body1">
                        Assignment: {getAssignmentByIdReq.data.jobs.length} jobs
                        - {getAssignmentByIdReq.data.stops.length} stops
                      </Typography>

                      {routeCalculation && (
                        <ColoredTextTypography
                          color="grey"
                          shade="600"
                          variant="body2"
                          paragraph
                        >
                          {routeCalculation.distance} miles,{" "}
                          {routeCalculation.duration} drive time
                        </ColoredTextTypography>
                      )}
                    </Box>
                    <Box display="flex" flexDirection="row" alignItems="end">
                      <Tooltip title="View all GPS Updates">
                        <IconButton
                          size="small"
                          className={classes.iconButton}
                          aria-label="ping device"
                          onClick={handleViewGPSUpdates}
                        >
                          <Icon class="fa-solid fa-location-crosshairs" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Send diagnosis to Sentry">
                        <IconButton
                          size="small"
                          className={classes.iconButton}
                          aria-label="ping device"
                          onClick={handleDiagnosis}
                        >
                          <Icon class="fa-solid fa-heart-pulse" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Ping device">
                        <IconButton
                          size="small"
                          className={classes.iconButton}
                          aria-label="ping device"
                          onClick={handlePingDevice}
                        >
                          <Icon class="fa-solid fa-satellite" />
                        </IconButton>
                      </Tooltip>
                      <Divider orientation="vertical" flexItem />
                      <Tooltip title="Sync driver's device">
                        <IconButton
                          size="small"
                          className={classes.iconButton}
                          aria-label="Sync driver's device"
                          onClick={handleSyncDevice}
                        >
                          <Icon class="fa-solid fa-mobile-screen-button" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Any Order">
                        <IconButton
                          size="small"
                          style={{
                            background: anyOrder ? "#2196f3" : undefined,
                            color: anyOrder ? "white" : undefined,
                          }}
                          className={classes.iconButton}
                          aria-label="Sync driver's device"
                          onClick={toggleAnyOrder}
                        >
                          <Icon color="inherit" class="fa-solid fa-shuffle" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Refresh" aria-label="Refresh">
                        <IconButton
                          size="small"
                          className={classes.iconButton}
                          aria-label="reload"
                          onClick={() => handleFetchAssignmentById(true)}
                        >
                          <Icon class="fa-solid fa-arrows-rotate" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Get Breakdown" aria-label="Breakdown">
                        <IconButton
                          size="small"
                          className={classes.iconButton}
                          aria-label="reload"
                          onClick={() =>
                            window.open(
                              `${REACT_APP_API}${REACT_APP_API_PREFIX}/workflow/assignments/${urlParams.id}/quote.html`
                            )
                          }
                        >
                          <Icon class="fa-solid fa-table-list" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        title="Chat with Driver"
                        aria-label="Chat with Driver"
                      >
                        <IconButton
                          className={classes.iconButton}
                          aria-describedby="MessageThread-popover"
                          aria-label="show more"
                          size="small"
                          onClick={handleSetMessageThreadContent}
                        >
                          <Badge
                            color="error"
                            variant="dot"
                            invisible={!showNewMessageBadge}
                          >
                            <Icon class={"fa-solid fa-comment-dots"} />
                          </Badge>
                        </IconButton>
                      </Tooltip>
                      {issuesList.length > 0 && (
                        <IconButton
                          className={classes.iconButton}
                          aria-describedby="Issues-popover"
                          aria-label="show more"
                          size="small"
                          onClick={(event) =>
                            handleChangeDynamicContent(event, ISSUES)
                          }
                        >
                          <Icon
                            style={{ color: "#ff9800" }}
                            class="fa-solid fa-triangle-exclamation"
                          />
                        </IconButton>
                      )}
                    </Box>
                  </Box>
                  <Box
                    width={1}
                    height={300}
                    overflow="hidden"
                    borderRadius={8}
                    boxShadow={2}
                    mb={3}
                  >
                    <AssignmentPreview
                      stops={getAssignmentByIdReq.data.stops}
                      markers={gpsUpdates}
                      currentStop={getCurrentStop()}
                      markerInfoWindowContentGenerator={getInfoWindowString}
                      onStopClick={handleOnSelectStop}
                      onRouteCalculation={handleRouteCalculation}
                      apiKey="AIzaSyDCifXDGIUK85MxZy3qamMZVW08sbdqzO4"
                      options={{ showAllMarkers, satelliteMapType }}
                      configPanel={
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                size="small"
                                color="primary"
                                checked={showAllMarkers}
                                onChange={toggleShowAllMarkers}
                              />
                            }
                            label="Show All"
                          />
                          <FormControlLabel
                            control={
                              <Switch
                                size="small"
                                color="primary"
                                checked={satelliteMapType}
                                onChange={toggleSatelliteMapType}
                              />
                            }
                            label="Satellite"
                          />
                        </FormGroup>
                      }
                    />
                  </Box>
                  <Box width={1}>
                    <AddJob onSubmit={handleCreateJob} />
                  </Box>
                  <Box width={1}>
                    {getAssignmentByIdReq.data.jobs.map((job, jobIndex) => {
                      return (
                        <Job key={job.id} job={job}>
                          {job.stops &&
                            job.stops.map((s, stopIndex) => (
                              <Stop
                                key={s.id}
                                stop={s}
                                currentStop={getCurrentStop()}
                                currentStopOrder={job.currentStopOrder}
                                completed={s.completed}
                                onSelect={() =>
                                  handleOnSelectStop({ jobIndex, stopIndex })
                                }
                                onSkip={handleOnSkip(s)}
                                openPopover={(event) =>
                                  handleOpenPopover(event, CREATE_STOP, s)
                                }
                              />
                            ))}
                        </Job>
                      );
                    })}
                  </Box>
                </>
              )}
            </Box>
          </div>

          <AssigmentDetailDynamicContent>
            {currentDynamicContent === MESSAGES &&
              getMessagesByAssigmentIdReq &&
              getMessagesByAssigmentIdReq.success && (
                <MessageThread
                  messages={chatReducerState.messageList}
                  onResolve={handleResolveChanged}
                  resolved={resolved}
                  onCreateMessage={handleCreateMessage}
                />
              )}

            {currentDynamicContent === ISSUES && issuesList.length && (
              <IssueList issues={issuesList} onDismiss={handleDismiss} />
            )}
            {currentDynamicContent === GPS_UPDATES && (
              <GPSUpdateList
                gpsUpdates={gpsUpdates}
                onDismiss={handleDismiss}
              />
            )}

            {currentDynamicContent === TASK && getCurrentStop() && (
              <TaskList
                openPopover={(event) => handleOpenPopover(event, CREATE_TASK)}
                stop={getCurrentStop()}
              />
            )}
          </AssigmentDetailDynamicContent>
        </>
      )}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Box className={classes.popoverContainer} p={2}>
          {popoverContent === CREATE_STOP && (
            <>
              <Box fontSize="h5.fontSize" fontWeight="fontWeightBold" mb={2}>
                Create Stop, order: {newStop ? newStop.stopOrder : ""}
              </Box>

              <SearchLocationField
                value={location}
                onChange={(event, newValue) => setLocation(newValue)}
                getOptionSelected={(option, value) => option.id === value.id}
              />

              <Box mt={2}>
                <ColoredButton
                  color="green"
                  variant="contained"
                  size="large"
                  fullWidth
                  onClick={handleOnCreateStop}
                  disabled={createStopReq && createStopReq.loading}
                >
                  Create
                </ColoredButton>
              </Box>
            </>
          )}

          {popoverContent === CREATE_TASK && (
            <>
              <Box fontSize="h5.fontSize" fontWeight="fontWeightBold" mb={2}>
                Add Task
              </Box>

              <FormControl fullWidth>
                <InputLabel id="kind-select-label">Kind</InputLabel>
                <Select
                  labelId="kind-select-label"
                  id="kind-select"
                  value={kind}
                  label="Kind"
                  onChange={(event) => setKind(event.target.value)}
                >
                  <MenuItem value="pickupTrailer">Pickup Trailer</MenuItem>
                </Select>
              </FormControl>

              <Box mt={2}>
                <ColoredButton
                  color="green"
                  variant="contained"
                  size="large"
                  fullWidth
                  onClick={(event) => handleOnCreateTask(event, kind)}
                  disabled={createTaskReq && createTaskReq.loading}
                >
                  Create
                </ColoredButton>
              </Box>
            </>
          )}
        </Box>
      </Popover>
    </>
  );
}
