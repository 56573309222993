import grey from '@material-ui/core/colors/grey';

export default theme => ({
  searchBox: {
    borderBottom: '1px solid',
    borderBottomColor: grey[200],
  },
  driverList: {
    height: 'calc(100% - 88px)',
    overflow: 'auto',
  },
  container: {
    height: 'calc(100vh - 97px)',
    width: '230px'
  },
  fab: {
    position: 'fixed',
    zIndex: 1200,
    bottom: theme.spacing(2),
    left: theme.spacing(2)
  }
});
