import { getColor } from 'helpers';

function formatShade(color, shade) {
  if (!color) {
    return 800;
  } else {
    shade ||= 500;
  }
  if (shade < 100) {
    return 100;
  }
  return shade + 100;
}

export default theme => ({
  chip: {
    color: 'white',
    backgroundColor: getColor,
    '&:hover, &:focus': {
      backgroundColor: props => getColor({color: props.color || 'grey', shade: formatShade(props.color, props.shade)}),
    },
    '&:active': {
      backgroundColor: props => getColor({color: props.color || 'grey', shade: formatShade(props.color, props.shade)}),
    }
  }
});
