import { Chip, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
// import PropTypes from 'prop-types';
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import React from "react";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function IssueListItem({ issue, onClick }) {
  // const [ state, setState ] = React.useState(initialValue);
  const classes = useStyles(styles);

  return (
    <Box mb={2}>
      <Paper variant="outlined">
        <Box
          display="flex"
          justifyContent="space-between"
          className={classes.container}
        >
          <Box display="flex" flexDirection="column">
            <Box fontSize="body1.fontSize">{issue.name}</Box>
            <Typography variant="caption" color="textSecondary">
              {moment(issue.createdAt).format("lll")}
            </Typography>
          </Box>
          <Chip label="Dismiss" variant="contained" onClick={onClick} />
        </Box>
      </Paper>
    </Box>
  );
}
