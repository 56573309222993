import React, { useRef, useEffect } from "react";
import GoogleMapReact from "google-map-react";

// material-ui
import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Popover from "@material-ui/core/Popover";

import MenuIcon from "@material-ui/icons/Menu";

// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

const getInfoWindowString = (element) =>
  `<div><div style="font-size: 16px;">${
    element && element.id ? element.id : "Not Set"
  }</div></div>`;

export default function AssignmentPreview({
  stops,
  currentStop,
  markers,
  markerInfoWindowContentGenerator,
  onStopClick,
  apiKey,
  onRouteCalculation,
  configPanel,
  options = { showAllMarkers: true },
}) {
  const [configPanelPopover, setConfigPanelPopover] = React.useState(null);
  const open = Boolean(configPanelPopover);

  const mapMarkers = useRef([]);
  const mapStopMarkers = useRef([]);
  const mapInfoWindows = useRef([]);
  const polyline = useRef(null);

  const blueMarker = useRef(null);
  const yellowMarker = useRef(null);
  const redMarker = useRef(null);
  const greenMarker = useRef(null);
  const gpsUpdateMarker = useRef(null);

  const classes = useStyles();

  const config = useRef();

  const resetMarkers = () => {
    mapInfoWindows.current.forEach((iw) => {
      iw.close();
    });
    mapMarkers.current.forEach((m, i) => {
      const last = i === mapMarkers.current.length - 1;
      if (!last) {
        m.setZIndex(999);
        m.setIcon(redMarker.current);
      }
    });
  };

  async function renderPolylines(map, maps) {
    config.current = { map, maps };
    blueMarker.current = {
      url: "/assets/img/blueMarker.png",
      scaledSize: new maps.Size(35, 51),
    };
    yellowMarker.current = {
      url: "/assets/img/yellowMarker.png",
      scaledSize: new maps.Size(35, 51),
    };
    redMarker.current = {
      url: "/assets/img/redMarker.png",
      scaledSize: new maps.Size(35, 51),
    };
    greenMarker.current = {
      url: "/assets/img/greenMarker.png",
      scaledSize: new maps.Size(35, 51),
    };
    gpsUpdateMarker.current = {
      url: "/assets/img/gpsUpdateMarker.png",
      scaledSize: new maps.Size(35, 51),
    };

    const waypoints = stops.map((s, index) => ({
      location: s,
      stopover: true,
    }));

    if (waypoints.length) {
      waypoints.forEach((element, index) => {
        const marker = new maps.Marker({
          icon: element.location.completed
            ? greenMarker.current
            : blueMarker.current,
          position: element.location,
          map,
          optimized: false,
          zIndex: 9999,
        });
        marker.addListener("click", (event) => {
          onStopClick && onStopClick(event, element.location);
        });
        mapStopMarkers.current.push({ stop: element.location, marker });
      });
      const origin = waypoints.shift().location;
      const destination = waypoints.length ? waypoints.pop().location : origin;

      const directionsService = new maps.DirectionsService();
      const directionsDisplay = new maps.DirectionsRenderer({
        map,
        suppressMarkers: true,
      });

      directionsService.route(
        { origin, destination, waypoints, travelMode: maps.TravelMode.DRIVING },
        (response, status) => {
          if (status === "OK") {
            onRouteCalculation(
              response,
              response.routes.reduce(
                (acc, current) => {
                  acc.distance = current.legs.reduce(
                    (a, c) => a + c.distance.value,
                    0
                  );
                  acc.duration = current.legs.reduce(
                    (a, c) => a + c.duration.value,
                    0
                  );
                  return acc;
                },
                { distance: 0, duration: 0 }
              )
            );

            directionsDisplay.setDirections(response);
          }
        }
      );
    }

    if (markers.length) {
      markers.forEach((element, index) => {
        if (element) {
          const last = index === markers.length - 1;
          try {
            const infoWindow = new maps.InfoWindow({
              content: markerInfoWindowContentGenerator
                ? markerInfoWindowContentGenerator(element)
                : getInfoWindowString(element),
            });
            const marker = new maps.Marker({
              position: element,
              title: element.deviceName,
              optimized: false,
              icon: redMarker.current,
            });
            if (last || options.showAllMarkers) {
              marker.setMap(map);
            }
            if (last) {
              marker.setIcon(gpsUpdateMarker.current);
              marker.setZIndex(99999);
            }
            marker.addListener("click", () => {
              resetMarkers();
              if (!last) {
                marker.setZIndex(999999);
                marker.setIcon(yellowMarker.current);
              }
              infoWindow.open({ shouldFocus: false, anchor: marker, map });
            });
            infoWindow.addListener("closeclick", () => resetMarkers());
            mapMarkers.current.push(marker);
            mapInfoWindows.current.push(infoWindow);
          } catch (e) {
            console.error(e);
          }
        }
      });

      polyline.current = new maps.Polyline({
        path: markers,
        geodesic: true,
        strokeColor: "#388E3C",
        strokeOpacity: 1.0,
        strokeWeight: 6,
        map,
      });
    }
  }

  useEffect(() => {
    return () => {
      if (polyline.current) polyline.current.setMap(null);
    };
  }, [markers]);

  useEffect(() => {
    if (currentStop) {
      const stopMarker = mapStopMarkers.current.find(
        (sm) => sm.stop.id === currentStop.id
      );
      if (stopMarker) {
        mapStopMarkers.current.forEach((sm) => {
          sm.marker.setZIndex(9999);
          sm.marker.setIcon(
            sm.stop.completed ? greenMarker.current : blueMarker.current
          );
        });
        stopMarker.marker.setZIndex(999999);
        stopMarker.marker.setIcon(yellowMarker.current);
      }
    }
  }, [currentStop]);

  useEffect(() => {
    const markers = mapMarkers.current;
    if (config.current) {
      if (options.showAllMarkers) {
        markers.forEach((marker) => marker.setMap(config.current.map));
      } else {
        markers.forEach((marker, index) =>
          marker.setMap(
            index === markers.length - 1 ? config.current.map : null
          )
        );
      }
    }
    return () => {
      markers.forEach((marker) => marker.setMap(null));
    };
  }, [options.showAllMarkers]);

  useEffect(() => {
    if (config.current) {
      config.current.map.setMapTypeId(
        options.satelliteMapType ? "satellite" : "roadmap"
      );
    }
  }, [options.satelliteMapType]);

  return (
    <div className={classes.wrapper}>
      {configPanel && (
        <Box className={classes.configPanel}>
          <MenuIcon
            onClick={(event) => setConfigPanelPopover(event.currentTarget)}
          />
          <Popover
            id={open ? "config-panel-popover" : undefined}
            open={open}
            anchorEl={configPanelPopover}
            onClose={() => setConfigPanelPopover(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Box p={1} ml={1}>
              {configPanel}
            </Box>
          </Popover>
        </Box>
      )}
      <GoogleMapReact
        className={classes.map}
        bootstrapURLKeys={{ key: apiKey }}
        center={markers.length ? markers[markers.length - 1] : stops[0]}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => renderPolylines(map, maps)}
        defaultZoom={11}
        options={{ disableDefaultUI: true }}
      />
    </div>
  );
}
