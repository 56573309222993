import * as queryString from 'query-string';
import pick from 'lodash.pick';

export function getJsonFromUrl(location) {
  const query = location.search.substr(1);
  const result = {};
  query.split('&').forEach((part) => {
    const item = part.split('=');
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
}

export function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(function() {
    console.log('Async: Copying to clipboard was successful!');
  }, function(err) {
    console.error('Async: Could not copy text: ', err);
    fallbackCopyTextToClipboard(text);
  });
}

function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}

export function setFavicon(icon) {
  const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = icon;
  document.getElementsByTagName('head')[0].appendChild(link);
}


export function removeFalsyValues(object) {
  return pick(
    object,
    Object.keys(object).filter((key) => Boolean(object[key]))
  );
}

export function addObjectParamsToPath(path, params) {
  params = removeFalsyValues(params);
  if (Object.keys(params).length) {
    path += `?${objectToQueryParams(params)}`;
  }
  return path;
}

export function addQueryParamsToPath(path, queryParams) {
  const params = queryParams
    ? queryParamsToObject(queryParams)
    : hrefToObject();
  return addObjectParamsToPath(path, params);
}

export function objectToQueryParams(object) {
  return queryString.stringify(object, { arrayFormat: 'comma' });
}

export function queryParamsToObject(hash, selection = null) {
  if (!hash || !hash.includes('?')) {
    return {};
  }
  const search = `?${hash.split('?')[1]}`;
  let object = queryString.parse(search, { arrayFormat: 'comma' });
  if (selection?.length) {
    object = pick(object, selection);
  }
  return object;
}

export function hrefToObject() {
  const search = `?${window.location.href.split('?')[1]}`;
  return queryString.parse(search, { arrayFormat: 'comma' });
}

export function hrefContainsProperty(attr) {
  const params = hrefToObject();
  return Boolean(params[attr]);
}

export function urlContainsProperty(url, attr) {
  const params = queryParamsToObject(url, [attr]);
  return Boolean(Object.keys(params).length);
}
