import { REACT_APP_API_PREFIX } from "config/env";
import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "workflow";
const stops = `${REACT_APP_API_PREFIX}/${service}/stops`;
const jobs = `${REACT_APP_API_PREFIX}/${service}/jobs`;

export function getStops(filters) {
  const url = `${stops}${filters ? `?filters=${JSON.stringify(filters)}` : ""}`;
  return {
    method: GET,
    url,
  };
}

export function getStopById(id) {
  const url = `${stops}/${id}/full`;
  return {
    method: GET,
    url,
  };
}

export function updateStop(id, body) {
  const url = `${stops}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteStop(id) {
  const url = `${stops}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createStop(body) {
  const url = `${stops}`;
  return {
    method: POST,
    url,
    body,
  };
}

export function createEmptyTrailer(body) {
  const url = `${jobs}/emptyTrailer`;
  return {
    method: POST,
    url,
    body,
  };
}
