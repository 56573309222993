import React from 'react';
// import PropTypes from 'prop-types';

// material-ui
import { makeStyles } from '@material-ui/core/styles';

// styles
import styles from './styles';

const useStyles = makeStyles(styles);

export default function EmptySpot({width = 35}) {
  const classes = useStyles({width});

  return (
    <div className={classes.container}>&nbsp;</div>
  );
}

