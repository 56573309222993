import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

// material-ui
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

// custom
import Path from 'components/Path';

// styles
import styles from './styles';

class Trip extends Component {
  static propTypes = {
    classes: PropTypes.object,
    trip: PropTypes.array,
    onSelect: PropTypes.func,
    selected: PropTypes.func,
    darkMode: PropTypes.bool,
  };

  // constructor(...args) {
  //   super(...args);
  //   hotkeys('*', (e) => {
  //     if (hotkeys.alt) {
  //       this.setState({ focusable: true });
  //     }
  //     if (e.key === 'Escape') {
  //       this.setState({ focusSavers: [], focusHandlers: [] });
  //       this.props.refresh();
  //     }
  //   });
  // }

  render() {
    const { trip, onSelect, selected, darkMode } = this.props;

    return (
      <div
        onKeyPress={e => console.log(e)}
      >
        <Grid
          container
        >
          {
            trip.map((path, i) => (
              <Grid item key={`Trip_${i}_${path.address.address_id}`}>
                <Path
                  first={Number(i) === 0}
                  last={Number(i) === trip.length - 1}
                  path={path}
                  onSelect={onSelect}
                  darkMode={darkMode}
                  selected={selected}
                  driveTime={!(Number(i) === trip.length - 1)
                    ? moment.utc(moment(trip[Number(i) + 1].arrival_time).diff(moment(path.departure_time))).format("H:mm")
                    : undefined
                  }
                />
              </Grid>
            ))
          }
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(Trip);
