import {
  GET,
} from 'constants/methods';

const service = 'v3/trip-validation';
const tripReports = `/${service}`;

export function getTripReports(filters, id) {
  let url;
  if (id) {
    url = `${tripReports}/${id}`;
  } else {
    url = `${tripReports}?filter=${JSON.stringify(filters)}`;
  }
  return {
    method: GET,
    url,
  };
}

export function getTripHistorical(filters) {
  const url = `${tripReports}/historical?filter=${JSON.stringify(filters)}`;
  return {
    method: GET,
    url,
  };
}
