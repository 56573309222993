export default theme => ({
  container: {
    position: 'relative',
    display: 'flex',
    width: '45%',
    flexDirection: 'column',
    boxShadow: theme.shadows[4],
    "&::before": {
      //content: '""', TODO: Implement dynamic position of arrow
      position: "absolute",
      top: props => {
        let pos = props.arrowposition || '10px';
        if (typeof props.arrowposition === 'number') {
          pos = `${props.arrowposition}px`;
        }
        return pos;
      },
      left: 0,
      width: 20,
      height: 20,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[4],
      transform: "translate(-50%, 50%) rotate(135deg)"
    }
  },
  paper: {
    zIndex: 1,
    height: '100%',
    borderRadius: 0,
    boxShadow: 'none'
  },
  content: {
    height: 'calc(100vh - 130px)',
    position: 'relative',
    borderRadius: 4
  }
});
