import moment from 'moment';
import { zeroPadStart } from "./string";

export function diff(start, end) {
  var diff = end.getTime() - start.getTime();
  var hours = Math.floor(diff / 1000 / 60 / 60);
  diff -= hours * 1000 * 60 * 60;
  var minutes = Math.floor(diff / 1000 / 60);

  // If using time pickers with 24 hours format, add the below line get exact hours
  if (hours < 0)
    hours = hours + 24;

  return {hours, minutes};
}

export function extractWeedDay(date) {
  if (!date) {
    date = new Date();
  }
  return date.toDateString().slice(0, 3);
}

export function formatAMPM(date) {
  if (!date) {
    date = new Date();
  }
  let hours = date.getHours();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  return {hours, minutes: zeroPadStart(date.getMinutes()), seconds: zeroPadStart(date.getSeconds()), ampm};
}

export function secondsToHMS(value) {
  const sec_num = parseInt(value, 10);
  const hours   = Math.floor(sec_num / 3600);
  const minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  const seconds = sec_num - (hours * 3600) - (minutes * 60);

  return {hours, minutes, seconds};
}

export function metersToMiles(value) {
  const miles = parseInt(value, 10) / 1609.344;
  return (Math.round(miles * 100) / 100).toFixed(2);
}

export function getStartAndEndOfDate (date, range) {
  const d = moment(date);
  if (!['day', 'days', 'month', 'months', 'week', 'weeks', 'year', 'years'].includes(range)) {
    range = 'day';
  }
  return {start: d.startOf(range).format(), end: d.endOf(range).format()};
}

export function dateFormat(date) {
  return moment(date).format('YYYY-MM-DD');
}