import { SET_STORED_AREA } from "constants/app";
import { getKey, setKey } from "helpers";

export default (state = getKey("area", "1"), action) => {
  switch (action.type) {
    case SET_STORED_AREA:
      setKey("area", action.storedArea);
      return action.storedArea;
    default:
      return state;
  }
};
