import { networkAction } from 'helpers/network/networkAction';

import {
  GET_STOPS,
  GET_STOP_BY_ID,
  DELETE_STOP,
  UPDATE_STOP,
  CREATE_STOP,
} from 'constants/stops';

import * as Api from 'api';

export const getStops = () => async dispatch => networkAction(
  dispatch,
  GET_STOPS,
  Api.getStops,
  []
);

export const getStopById = (id) => async dispatch => networkAction(
  dispatch,
  GET_STOP_BY_ID,
  Api.getStopById,
  [id]
);

export const deleteStop = (id) => async dispatch => networkAction(
  dispatch,
  DELETE_STOP,
  Api.deleteStop,
  [id]
);

export const createStop = (body) => async dispatch => networkAction(
  dispatch,
  CREATE_STOP,
  Api.createStop,
  [body]
);

export const updateStop = (id, body) => async dispatch => networkAction(
  dispatch,
  UPDATE_STOP,
  Api.updateStop,
  [id, body]
);
