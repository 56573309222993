import { REQUEST_INITIAL_STATE } from '../constants';
import { sortBy } from "./array";

export function handleLoading({state, action}) {
  return {
    ...state,
    [action]: {
      ...REQUEST_INITIAL_STATE,
      loading: true
    }
  }
}

export function handleSuccess({state, action, payload, options = {}}) {
  const message = payload && (payload.message || '');
  let data = payload;

  if (options.sortBy) {
    data.sort((a, b) => sortBy(a, b, options.sortBy, options.order, options.type));
  }

  return {
    ...state,
    [action]: {
      ...state[action],
      loading: false,
      success: true,
      data: data,
      message
    }
  };
}

export function handleError({state, action, payload}) {
  const message = payload && (payload.message || payload.description);
  return {
    ...state,
    [action]: {
      ...state[action],
      loading: false,
      success: false,
      message: message || "There was an error, please try again" 
    }
  };
}
