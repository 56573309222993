// import PropTypes from 'prop-types';
// material-ui
import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  Popover,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import * as Actions from "actions";
import ColoredButton from "hooks/ColoredButton";
import SearchLocationField from "hooks/SearchLocationField";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function AddJob({ onSubmit }) {
  const [anchorEl, setAnchorEl] = React.useState();
  const [order, setOrder] = React.useState("1");
  const [locationFrom, setLocationFrom] = React.useState();
  const [locationTo, setLocationTo] = React.useState();
  const [nodeFrom, setNodeFrom] = React.useState();
  const [nodeTo, setNodeTo] = React.useState();
  const [kindFrom, setKindFrom] = React.useState("shipcon");
  const [kindTo, setKindTo] = React.useState("shipcon");
  const classes = useStyles(styles);

  const nodes = useSelector((state) => state.nodes);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (anchorEl && nodes.length === 0) {
      dispatch(Actions.getNodes());
    } else {
      setNodeFrom();
      setLocationFrom();
      setNodeTo();
      setLocationTo();
      setKindFrom("shipcon");
      setKindTo("shipcon");
      setOrder("1");
    }
  }, [anchorEl]);

  function open(e) {
    setAnchorEl(e.currentTarget);
  }

  function close() {
    setAnchorEl();
  }

  function handleOrderChanged(e) {
    setOrder(e.target.value);
  }

  function handleLocationFromChanged(e, v) {
    setLocationFrom(v);
  }

  function handleLocationToChanged(e, v) {
    setLocationTo(v);
  }

  function handleNodeFromChanged(e, v) {
    setNodeFrom(v);
  }

  function handleNodeToChanged(e, v) {
    setNodeTo(v);
  }

  function handleSubmit() {
    console.log({
      jobOrder: Number(order),
      pickupLocationType: kindFrom,
      pickupLocationRefID:
        kindFrom === "warehouse" ? nodeFrom.id : locationFrom.id,
      dropLocationType: kindTo,
      dropLocationRefID: kindTo === "warehouse" ? nodeTo.id : locationTo.id,
    });
    onSubmit({
      jobOrder: Number(order),
      pickupLocationType: kindFrom,
      pickupLocationRefID:
        kindFrom === "warehouse" ? nodeFrom.id : locationFrom.id,
      dropLocationType: kindTo,
      dropLocationRefID: kindTo === "warehouse" ? nodeTo.id : locationTo.id,
    });

    setAnchorEl();
  }

  function isDisabled() {
    if (kindFrom === "warehouse" && !nodeFrom?.id) return true;
    if (kindFrom === "shipcon" && !locationFrom?.id) return true;
    if (kindTo === "warehouse" && !nodeTo?.id) return true;
    if (kindTo === "shipcon" && !locationTo?.id) return true;
    if (!order) return true;

    return false;
  }

  return (
    <>
      <Button
        color="primary"
        onClick={open}
        fullWidth
        className={classes.addButton}
      >
        <i class="fa-solid fa-circle-plus" style={{ paddingRight: 4 }}></i>
        Add Job
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={close}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Grid container className={classes.container} spacing={1}>
          <Grid item xs={12}>
            <Box fontSize="h5.fontSize" fontWeight="fontWeightBold" mb={2}>
              Add Job
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Order"
              value={order}
              fullWidth
              variant="outlined"
              type="number"
              onChange={handleOrderChanged}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="overline">FROM</Typography>
          </Grid>
          <Grid item xs={12}>
            <ButtonGroup
              size="small"
              color="primary"
              aria-label="outlined primary button group"
            >
              <Button
                variant={kindFrom === "shipcon" && "contained"}
                onClick={() => setKindFrom("shipcon")}
              >
                Location
              </Button>
              <Button
                onClick={() => setKindFrom("warehouse")}
                variant={kindFrom === "warehouse" && "contained"}
              >
                Node
              </Button>
            </ButtonGroup>
          </Grid>
          {kindFrom === "shipcon" && (
            <Grid item xs={12}>
              <SearchLocationField
                value={locationFrom}
                onChange={handleLocationFromChanged}
                getOptionSelected={(option, value) => option.id === value.id}
              />
            </Grid>
          )}
          {kindFrom === "warehouse" && (
            <Grid item xs={12}>
              <Autocomplete
                id="combo-box-demo"
                options={nodes}
                getOptionLabel={(option) => option.name}
                value={nodeFrom}
                onChange={handleNodeFromChanged}
                style={{ width: 300 }}
                renderOption={(option) => (
                  <Box>
                    <Typography>{option.name}</Typography>
                    <Typography variant="caption" color="textSecondary">
                      {option.line1}
                      <br />
                      {`${option.city}, ${option.state} ${option.zip}`}
                    </Typography>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Node"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="overline">TO</Typography>
          </Grid>
          <Grid item xs={12}>
            <ButtonGroup
              size="small"
              color="primary"
              aria-label="outlined primary button group"
            >
              <Button
                variant={kindTo === "shipcon" && "contained"}
                onClick={() => setKindTo("shipcon")}
              >
                Location
              </Button>
              <Button
                onClick={() => setKindTo("warehouse")}
                variant={kindTo === "warehouse" && "contained"}
              >
                Node
              </Button>
            </ButtonGroup>
          </Grid>
          {kindTo === "shipcon" && (
            <Grid item xs={12}>
              <SearchLocationField
                value={locationFrom}
                onChange={handleLocationToChanged}
                getOptionSelected={(option, value) => option.id === value.id}
              />
            </Grid>
          )}
          {kindTo === "warehouse" && (
            <Grid item xs={12}>
              <Autocomplete
                id="combo-box-demo"
                options={nodes}
                value={nodeTo}
                getOptionLabel={(option) => option.name}
                onChange={handleNodeToChanged}
                style={{ width: 300 }}
                renderOption={(option) => (
                  <Box>
                    <Typography>{option.name}</Typography>
                    <Typography variant="caption" color="textSecondary">
                      {option.line1}
                      <br />
                      {`${option.city}, ${option.state} ${option.zip}`}
                    </Typography>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Node"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          )}
          <Grid item xs={12} style={{ marginTop: 16 }}>
            <ColoredButton
              color="green"
              variant="contained"
              size="large"
              fullWidth
              disabled={isDisabled()}
              onClick={handleSubmit}
            >
              Create
            </ColoredButton>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
}
