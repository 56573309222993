export default theme => ({
  tabsRoot: {
    background: theme.palette.background.paper,
  },
  noBackground: {
    background: 'none',
  },
  tripReportLoading: {
    padding: 20,
    borderRadius: '4px',
  },
  bold: {
    fontWeight: 600,
  },
  center: { textAlign: 'center' },
  background: {
    background: theme.palette.background.default,
  },
  drawer: {
    padding: 20,
    background: theme.palette.background.paper,
  },
});
