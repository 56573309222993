import { Tooltip } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import { Edit } from "@material-ui/icons";
import AssignmentIcon from "@material-ui/icons/Assignment";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import * as Actions from "actions";
import IconButton from "creativeTim/CustomButtons/IconButton";
import ColoredTextTypography from "hooks/ColoredTextTypography";
import TaskListItem from "hooks/TaskListItem";
import React from "react";
import { useDispatch } from "react-redux";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function TaskList({
  stop,
  scrollableHeight,
  openPopover,
  ...other
}) {
  const [edit, setEdit] = React.useState(false);
  const [edited, setEdited] = React.useState(false);
  const classes = useStyles(styles);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!edit && edited) {
      dispatch(Actions.getAssignmentById(stop.assignmentID));
    }
  }, [edit]);

  const props = {
    bgcolor: "white",
    height: "calc(100vh - 130px)",
    ...other,
  };

  const scrollableProps = {
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    height: scrollableHeight || "calc(100vh - 172px)",
    p: 2,
  };

  return (
    <Box {...props} className={classes.content}>
      <Box {...scrollableProps}>
        <Box display="flex" justifyContent="space-between" mb={3}>
          <Box display="flex">
            <AssignmentIcon className={classes.icon} />
            <Box display="flex" flexDirection="column">
              <Box fontWeight="fontWeightBold" fontSize="h5.fontSize">
                Tasklist
              </Box>
              <ColoredTextTypography
                fontWeight="fontWeightBold"
                color="grey"
                shade="600"
              >
                {stop.totalTasks} task - {stop.totalCompletedTasks} completed
              </ColoredTextTypography>
            </Box>
          </Box>
          <Tooltip title={edit ? "Done Editing" : "Edit Tasks"}>
            <IconButton
              onClick={() => {
                setEdit((i) => !i);
                setEdited(true);
              }}
              style={{
                background: edit ? "#2196f3" : "rgba(155,155,155,0.3)",
                color: edit ? "white" : "black",
              }}
            >
              <Edit />
            </IconButton>
          </Tooltip>
        </Box>

        {stop.tasks.map((task) => (
          <TaskListItem
            edit={edit}
            key={task.id}
            task={task}
            tz={stop.location.timezone}
          />
        ))}
      </Box>

      <Box
        display="flex"
        alignItems="center"
        borderTop={1}
        borderColor="grey.500"
      >
        <Button
          className={classes.button}
          size="large"
          fullWidth
          onClick={openPopover}
          endIcon={stop.needsTrailer && <WarningRoundedIcon color="error" />}
        >
          <i class="fa-solid fa-circle-plus" style={{ marginRight: 4 }}></i>Add
          Task
        </Button>
      </Box>
    </Box>
  );
}
