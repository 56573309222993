import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// helpers
import { getJsonFromUrl } from 'helpers';

// actions
import * as Actions from 'actions';

// component
import Loading from 'components/Loading';
import WrapperTripsPage from '../component/WrapperTripsPage';

const mapStateToProps = state => ({
  app: state.app,
});

const mapDispatchToProps = dispatch => ({
  getTripReports: (...args) => dispatch(Actions.getTripReports(...args)),
  getTripHistorical: (...args) => dispatch(Actions.getTripHistorical(...args)),
  getAreas: (...args) => dispatch(Actions.getAreas(...args)),
});
class TripsPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    app: PropTypes.object,
    areas: PropTypes.array,
    getTripReports: PropTypes.func,
    getTripHistorical: PropTypes.func,
    getAreas: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: true,
    };
    this.refresh();
  }

  async refresh() {
    const { getAreas } = this.props;
    const resp = await getAreas();
    this.setState({
      areas: resp.payload,
      loading: false,
    });
  }

  render() {
    const {
      location,
      history,
      getTripReports,
      getTripHistorical,
      app,
    } = this.props;

    const {
      urlParams,
      areas,
      loading,
    } = this.state;

    if (loading) {
      return (
        <Loading
          loading={loading}
          message={'Loading'}
          filled
        />
      );
    }

    return (
      <WrapperTripsPage
        history={history}
        app={app}
        location={location}
        urlParams={urlParams}
        getTripReports={getTripReports}
        getTripHistorical={getTripHistorical}
        areas={areas}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TripsPage);
