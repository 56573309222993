import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import ContentLoader from "react-content-loader";

// material-ui
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Chip from "@material-ui/core/Chip";
import Tab from "@material-ui/core/Tab";
import Portal from "@material-ui/core/Portal";
import withWidth from "@material-ui/core/withWidth";
import Avatar from "@material-ui/core/Avatar";
import History from "@material-ui/icons/History";
import Close from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Warning from "@material-ui/icons/Warning";
import Place from "@material-ui/icons/Place";
import GoogleMapReact from "google-map-react";
import LinearScale from "@material-ui/icons/LinearScale";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import LocalShipping from "@material-ui/icons/LocalShipping";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";

// reactor
import Page from "components/Page";

// custom
import TripReport from "components/TripReport";
import Trip from "components/Trip";
import DatePicker from "components/DatePicker";

// constants
import { icons } from "constants/path";

import { HistoryItemLoading, TripReportLoading } from "constants/loading";

// styles
import styles from "./styles";
import darkGoogleMaps from "./darkGoogleMaps.json";

class WrapperTripsPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    width: PropTypes.string,
    areas: PropTypes.array,
    app: PropTypes.object,
    getTripReports: PropTypes.func,
    getTripHistorical: PropTypes.func,
  };

  static contextTypes = {
    MessageCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      drawerOpen: false,
      tripReport: undefined,
      historical: undefined,
      loading: true,
      issueOnly: urlParams.issueOnly
        ? JSON.parse(urlParams.issueOnly)
        : undefined,
      requestedHistory: urlParams.requestedHistory
        ? JSON.parse(urlParams.requestedHistory)
        : undefined,
      date: urlParams.date ? moment(urlParams.date).toDate() : new Date(),
    };
    if (urlParams.requestedHistory) {
      this.retrieveHistory(JSON.parse(urlParams.requestedHistory));
    } else {
      this.getTripReports(urlParams.index ? Number(urlParams.index) : 0);
    }
  }

  onSelect(path, report) {
    this.setState({
      selectedPath: path,
      selectedReport: report,
    });
  }

  getHistoryDrawer() {
    const { history, app } = this.props;

    const { index, historical, loading, date } = this.state;

    return (
      <div>
        {loading ? (
          <div style={{ padding: 20 }}>
            <ContentLoader
              primaryColor={app.darkMode ? "#757575" : "#f3f3f3"}
              secondaryColor={app.darkMode ? "#616161" : "#ecebeb"}
            >
              {HistoryItemLoading}
            </ContentLoader>
          </div>
        ) : (
          <div style={{ height: "100vh", overflow: "scroll" }}>
            {historical && historical.length ? (
              <List component="nav">
                {historical &&
                  historical.map((h, i) => (
                    <ListItem
                      key={`historical-${i}`}
                      button
                      onClick={() => {
                        this.retrieveHistory(h);
                        history.push(
                          `?index=${index}&date=${moment(date).format(
                            "YYYY-MM-DD"
                          )}&requestedHistory=${JSON.stringify(h)}`
                        );
                      }}
                    >
                      <ListItemIcon>
                        <Avatar>
                          {`${h.user.first_name[0]}${h.user.last_name[0]}`.toUpperCase()}
                        </Avatar>
                      </ListItemIcon>
                      <ListItemText
                        primary={`${h.user.first_name} ${h.user.last_name}`}
                        secondary={moment(h.timestamp).calendar()}
                      />
                    </ListItem>
                  ))}
              </List>
            ) : (
              <div style={{ padding: 20, textAlign: "center" }}>
                <Typography>No History Available</Typography>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  async getTripReports(index) {
    const { areas, getTripReports, getTripHistorical } = this.props;

    const { date } = this.state;

    this.setState({ loading: true });

    const resp = await Promise.all([
      getTripReports({
        date: moment(date).format("YYYY-MM-DD"),
        area_id: areas[index].id,
      }),
      getTripHistorical({
        date: moment(date).format("YYYY-MM-DD"),
        area_id: areas[index].id,
      }),
    ]);

    const tripReport = resp[0].payload;
    tripReport.forEach((tr, i) => (tr.id = i));

    this.setState({
      tripReport,
      historical: resp[1].payload,
      fetchedHistory: undefined,
      requestedHistory: undefined,
      loading: false,
    });
  }

  async retrieveHistory(history) {
    const { getTripReports } = this.props;
    this.setState({ loading: true });
    const resp = await getTripReports(undefined, history.sha);

    const fetchedHistory = resp.payload;
    fetchedHistory.forEach((tr, i) => (tr.id = i));
    this.setState({
      loading: false,
      fetchedHistory,
      requestedHistory: history,
    });
  }

  async renderPolylines(map, maps) {
    const { selectedReport } = this.state;

    const path = [];
    const waypoints = [];
    let origin;
    let destination;
    for (const key in selectedReport.path) {
      if (selectedReport.path.hasOwnProperty(key)) {
        const p = selectedReport.path[key];
        path.push({ lat: Number(p.address.lat), lng: Number(p.address.lng) });
        const point = new maps.LatLng(
          Number(p.address.lat),
          Number(p.address.lng)
        );
        if (Number(key) === 0) origin = point;
        else if (Number(key) === selectedReport.path.length - 1)
          destination = point;
        else {
          waypoints.push({
            location: point,
            stopover: true,
          });
        }
      }
    }
    const directionsService = new maps.DirectionsService();
    const directionsDisplay = new maps.DirectionsRenderer({
      map,
      suppressMarkers: true,
    });

    directionsService.route(
      {
        origin,
        destination,
        waypoints,
        travelMode: maps.TravelMode.DRIVING,
      },
      (response, status) => {
        if (status === "OK") {
          directionsDisplay.setDirections(response);
        }
      }
    );
  }

  render() {
    const { classes, history, areas, app } = this.props;

    const { MessageCenter } = this.context;

    const {
      index,
      drawerOpen,
      tripReport,
      historical,
      loading,
      date,
      selectedPath,
      fetchedHistory,
      requestedHistory,
      issueOnly,
      selectedReport,
    } = this.state;

    let resport = fetchedHistory || tripReport;

    if (issueOnly) {
      resport = resport.filter((r) => r.issues.length);
    }

    const Icon = selectedPath ? icons[selectedPath.vertex_sub_type] : <div />;

    return (
      <Page
        helmet={`Report - ${areas[index].name} - ${moment(date).calendar()}`}
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({ index: v });
              this.getTripReports(v);
              history.push(
                `?index=${v}&date=${moment(date).format("YYYY-MM-DD")}`
              );
            }}
            indicatorColor="primary"
            textColor="primary"
            scrollable
            scrollButtons="auto"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            {areas.map((area) => (
              <Tab key={area.id} label={area.name} />
            ))}
          </Tabs>
        }
      >
        <Grid
          container
          spacing={16}
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Grid container spacing={16}>
              <Grid item>
                <DatePicker
                  value={date}
                  label="Date"
                  onChange={(d) => {
                    history.push({
                      search: `?index=${index}&date=${moment(d).format(
                        "YYYY-MM-DD"
                      )}`,
                    });
                    this.state.date = d; // eslint-disable-line
                    this.setState({ date: d });
                    this.getTripReports(index);
                  }}
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={this.state.checkedA}
                      onChange={() => this.setState({ issueOnly: !issueOnly })}
                      value="checkedA"
                    />
                  }
                  label="Issue Only"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {historical && historical.length ? (
              <Chip
                avatar={
                  <Avatar className={classes.noBackground}>
                    {drawerOpen ? <Close /> : <History />}
                  </Avatar>
                }
                onClick={() => MessageCenter.toggle("right")}
                variant="outlined"
                label={
                  MessageCenter.isOpen("right")
                    ? "Close History"
                    : "Show History"
                }
              />
            ) : (
              <div />
            )}
          </Grid>
        </Grid>
        {loading ? (
          <ContentLoader
            height={120}
            width={1200}
            primaryColor={app.darkMode ? "#424242" : "#f3f3f3"}
            secondaryColor={app.darkMode ? "#616161" : "#ecebeb"}
            className={classes.tripReportLoading}
            style={{ background: app.darkMode ? "#424242" : "white" }}
          >
            {TripReportLoading}
          </ContentLoader>
        ) : (
          <div>
            {resport.length ? (
              <div>
                {requestedHistory ? (
                  <Typography variant="h5" className={classes.bold}>
                    <History /> {moment(requestedHistory.timestamp).calendar()}{" "}
                    Viewed by{" "}
                    {`${requestedHistory.user.first_name} ${requestedHistory.user.last_name}`}
                  </Typography>
                ) : (
                  <div />
                )}
                {resport.map((t, i) => (
                  <TripReport
                    report={t}
                    key={`tripReport_${i}`}
                    app={app}
                    selected={selectedPath}
                    onSelect={this.onSelect.bind(this)}
                  />
                ))}
              </div>
            ) : (
              <Grid
                container
                justify="center"
                alignItems="center"
                style={{ height: "30vh" }}
              >
                <Grid item className={classes.center}>
                  <LinearScale />
                  <Typography variant="h6">No Report Available</Typography>
                </Grid>
              </Grid>
            )}
          </div>
        )}
        <Portal container={MessageCenter.getPortalContainer("right")}>
          {MessageCenter.isOpen("right") ? this.getHistoryDrawer() : []}
        </Portal>
        <Drawer
          elevation={10}
          anchor="bottom"
          open={selectedPath !== undefined}
          onClose={() => this.setState({ selectedPath: undefined })}
          variant="temporary"
          classes={{
            paper: classes.shadow,
          }}
        >
          <div style={{ padding: 20 }}>
            {selectedPath !== undefined ? (
              <div>
                <Hidden smUp>
                  <Grid container justify="flex-end">
                    <Grid item>
                      <IconButton
                        onClick={() =>
                          this.setState({ selectedPath: undefined })
                        }
                      >
                        <Close />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Hidden>
                <Grid container spacing={16}>
                  <Grid item sm={12} md={6}>
                    {selectedReport ? (
                      <Grid container spacing={16} alignItems="center">
                        <Grid item style={{ width: 60 }}>
                          <LocalShipping
                            style={{ color: app.darkMode ? "white" : "black" }}
                          />
                        </Grid>
                        <Grid item style={{ width: "calc(100% - 76px)" }}>
                          <Typography variant="h6">
                            {selectedReport.driver.driver_name
                              ? selectedReport.driver.driver_name
                              : "Unkown Driver"}
                          </Typography>
                          <Divider />
                        </Grid>
                      </Grid>
                    ) : (
                      <div />
                    )}
                    <Grid container spacing={16}>
                      <Grid item style={{ width: 60 }}>
                        <Icon
                          style={{ color: app.darkMode ? "white" : "black" }}
                        />
                      </Grid>
                      <Grid item style={{ width: "calc(100% - 76px)" }}>
                        <Typography variant="h6">
                          {selectedPath.name}
                        </Typography>
                        <Typography color="textSecondary">
                          {selectedPath.address.street_address}{" "}
                          {selectedPath.address.city} {selectedPath.address.zip}{" "}
                          {selectedPath.address.state}
                        </Typography>
                        <Typography color="textSecondary" variant="caption">
                          {selectedPath.address.timezone}
                        </Typography>
                        <Typography color="textSecondary" variant="caption">
                          {selectedPath.vertex_type}{" "}
                          {selectedPath.vertex_sub_type} #
                          {selectedPath.vertex_id}
                        </Typography>
                        <Divider />
                        <Typography>
                          <b>Arrival:</b>{" "}
                          {moment(selectedPath.arrival_time).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )}
                        </Typography>
                        <Typography>
                          <b>Depature:</b>{" "}
                          {moment(selectedPath.departure_time).format(
                            "MMMM Do YYYY, h:mm:ss a"
                          )}
                        </Typography>
                        <Typography>
                          <b>Duration:</b>{" "}
                          {moment
                            .utc(
                              moment(selectedPath.departure_time).diff(
                                moment(selectedPath.arrival_time)
                              )
                            )
                            .format("HH:mm:ss")}
                        </Typography>
                        <Divider />
                      </Grid>
                    </Grid>
                    {selectedPath.issue.name ? (
                      <Grid container spacing={16} alignItems="center">
                        <Grid item style={{ width: 60 }}>
                          <Warning color="primary" />
                        </Grid>
                        <Grid item style={{ width: "calc(100% - 76px)" }}>
                          <Typography color="primary">
                            {selectedPath.issue.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    ) : (
                      <div />
                    )}
                    {selectedReport ? (
                      <Grid item>
                        <Trip
                          trip={selectedReport.path}
                          onSelect={(path) =>
                            this.setState({ selectedPath: path })
                          }
                          selected={selectedPath}
                          darkMode={app.darkMode}
                        />
                      </Grid>
                    ) : (
                      <div />
                    )}
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    md={6}
                    style={{ minHeight: 300, width: "100%" }}
                  >
                    <GoogleMapReact
                      bootstrapURLKeys={{
                        key: "AIzaSyDZP4-zeA7nmIj7zIW-GcGra1FSk7GKLwE",
                      }}
                      center={{
                        lat: Number(selectedPath.address.lat),
                        lng: Number(selectedPath.address.lng),
                      }}
                      onGoogleApiLoaded={({ map, maps }) =>
                        this.renderPolylines(map, maps)
                      }
                      options={
                        app.darkMode ? { styles: darkGoogleMaps } : undefined
                      }
                      defaultZoom={11}
                    >
                      <Place
                        lat={Number(selectedPath.address.lat)}
                        lng={Number(selectedPath.address.lng)}
                        text={selectedPath.name}
                        style={{
                          color: "#f44336",
                          marginTop: -45,
                          marginLeft: -17,
                        }}
                        fontSize="large"
                      />
                    </GoogleMapReact>
                  </Grid>
                </Grid>
              </div>
            ) : (
              <div />
            )}
          </div>
        </Drawer>
      </Page>
    );
  }
}
export default withWidth()(withStyles(styles)(WrapperTripsPage));
