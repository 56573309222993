import grey from '@material-ui/core/colors/grey';

export default theme => ({
  checkboxContainer: {
    width: 90,
    borderRadius: 6,
    background: grey[300],
    padding: 2
  },
  checkboxButton: {
    cursor: 'unset',
    textTransform: 'none',
    minWidth: 45,
    borderRadius: 6,
    padding: '1px 0',
    '&.active': {
      background: 'white'
    }
  }
});
