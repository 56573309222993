import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as Actions from "actions";
import useDebounce from 'hooks/useDebounce';
import RoundedTextField from 'hooks/RoundedTextField';

import SearchIcon from "@material-ui/icons/Search";

export default function SearchField({onSubmit, disabled}) {
  // const [ state, setState ] = React.useState(initialValue);

  const dispatch = useDispatch();

  const {search} = useSelector(state => state.assignments);

  const debouncedSearch = useDebounce(search || ' ', 1000);

  const handleSubmit = useCallback((s) => {
    onSubmit && onSubmit(s);
  }, [onSubmit]);

  useEffect(() => {
    if (debouncedSearch) {
      handleSubmit(debouncedSearch);
    }
  }, [debouncedSearch, handleSubmit]);

  return <RoundedTextField size="small" variant="outlined" placeholder="Search..." value={search}
    disabled={disabled} startIcon={<SearchIcon />}
    onChange={e => dispatch(Actions.setSearchDriver(e.target.value))}
    onKeyPress={e => {
      if (e.key === 'Enter') {
        e.preventDefault();
        handleSubmit(search);
      }
    }}
  />;
}
