import { REACT_APP_API_PREFIX } from "config/env";
import { DELETE, GET, POST, PUT } from "constants/methods";
import { dateFormat } from "helpers";

const service = "workflow";
const assignments = `${REACT_APP_API_PREFIX}/${service}/assignments`;
const issues = `${REACT_APP_API_PREFIX}/${service}/issues`;
const tasks = `${REACT_APP_API_PREFIX}/${service}/tasks`;
const stops = `${REACT_APP_API_PREFIX}/${service}/stops`;
const gpsUpdates = `${REACT_APP_API_PREFIX}/${service}/gpsUpdates`;
const search = `${REACT_APP_API_PREFIX}/locations/search`;

export function getAssignments(area, date) {
  const filters = [];

  if (area) {
    filters.push({ name: "assignments.areaID", comparison: "eq", value: area });
  }

  if (date) {
    const d = new Date(date);
    const start = dateFormat(d);
    d.setDate(d.getDate() + 1);
    filters.push({ name: "assignments.date", comparison: "gte", value: start });
    filters.push({
      name: "assignments.date",
      comparison: "lt",
      value: dateFormat(d),
    });
  }

  const url = `${assignments}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getAssignmentById(id) {
  const url = `${assignments}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function syncDevice(id) {
  const url = `${assignments}/${id}/syncDevice`;
  return {
    method: POST,
    url,
    body: {},
  };
}

export function command(id, body) {
  const url = `${assignments}/${id}/command`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function updateAssignment(id, body) {
  const url = `${assignments}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteAssignment(id) {
  const url = `${assignments}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createAssignment(body) {
  const url = `${assignments}`;
  return {
    method: POST,
    url,
    body,
  };
}

export function dismissIssue(issueID) {
  return {
    method: PUT,
    url: `${issues}/${issueID}`,
    body: { dismissed: true },
  };
}

export function searchLocation(area, location) {
  const url = `${search}?area=${area}&location=${location}`;
  return { method: GET, url };
}

export function createTask(task) {
  return {
    method: POST,
    url: tasks,
    body: task,
  };
}

export function createStop(stop) {
  return {
    method: POST,
    url: `${stops}/manual`,
    body: stop,
  };
}

export function getGpsUpdatesAssignmentId(id) {
  const filter = JSON.stringify([
    { name: "gpsUpdates.assignmentID", comparison: "eq", value: id },
  ]);
  const url = `${gpsUpdates}?filters=${filter}`;
  return {
    method: GET,
    url,
  };
}
