import { REQUEST_INITIAL_STATE } from '../constants';
import {
  GET_FILE_BY_ID,
} from '../constants';

import { handleLoading, handleSuccess, handleError } from 'helpers';

const initialState = {
  fetchedFiles: {},
  [GET_FILE_BY_ID]: REQUEST_INITIAL_STATE
};

export default (state = initialState, {type, payload}) => {

  switch (type) {
    case `${GET_FILE_BY_ID}_LOADING`:
      return handleLoading({state, action: GET_FILE_BY_ID});
    case `${GET_FILE_BY_ID}_SUCCESS`:
      return handleSuccess({state, action: GET_FILE_BY_ID, payload});
    case `${GET_FILE_BY_ID}_ERROR`:
      return handleError({state, action: GET_FILE_BY_ID, payload});

    default:
      return state;
  }
};