import React, { useState, useRef, useEffect, createContext, useContext } from 'react';
import Centrifuge from 'centrifuge';
import moment from 'moment';

import {
  CENTRIFUGE_BASE_URL,
  CENTRIFUGE_TOKEN
} from 'config/env';

let centrifuge = null;
const CentrifugeContext = createContext(null);

export const CentrifugeProvider = ({children}) => {
  const [isConnected, setIsConnected] = useState(false);
  const initialized = useRef(false);
  const subscriptions = useRef({});

  useEffect(() => {
    if (!centrifuge) {
      centrifuge = new Centrifuge(CENTRIFUGE_BASE_URL, { sockjs: window.SockJS });
      centrifuge.setToken(CENTRIFUGE_TOKEN);

      centrifuge.on('connect', function(ctx) {
        console.log(moment().format('HH:mm'), "connected", ctx);
        console.log("socket init? ", initialized);
        setIsConnected(true);
      });
  
      centrifuge.on('disconnect', function(ctx) {
        console.log(moment().format('HH:mm'), "disconnected", ctx);
        unsubscribeAll();
        setIsConnected(false);
      });
    }

    return () => {
      if (centrifuge && isConnected) {
        unsubscribeAll();
        centrifuge.disconnect();
      }
    }
  }, [isConnected]);

  const connect = () => {
    if (centrifuge && !isConnected) {
      centrifuge.connect();
      if (!initialized.current) {
        initialized.current = true;
      }
    }
  }

  const disconnect = () => {
    if (centrifuge && isConnected) {
      centrifuge.disconnect();
    }
  }
  
  const subscribe = (channel, callback) => {
    if (centrifuge && isConnected) {
      if (!subscriptions[channel]) {
        const sub = centrifuge.subscribe(channel, callback);
        subscriptions.current = {
          ...subscriptions.current,
          [channel]: sub
        };
      }
    }
  }

  const unsubscribe = (channel) => {
    if (centrifuge && isConnected) {
      if (subscriptions[channel]) {
        const current = subscriptions.current;
        delete current[channel];
        subscriptions.current = current;
      }
    }
  }

  const unsubscribeAll = () => {
    const channels = Object.keys(subscriptions.current);
    channels.forEach(channel => subscriptions.current[channel].unsubscribe());
    subscriptions.current = {};
  }

  return <CentrifugeContext.Provider value={{isConnected, initialized: initialized.current, connect, disconnect, subscribe, unsubscribe, unsubscribeAll}}>
    {children}
  </CentrifugeContext.Provider>;
}

export const useCentrifuge = () => {
  const context = useContext(CentrifugeContext);
  if (!context.initialized && !context.isConnected) {
    context.connect();
  }
  return context;
};
