import { networkAction } from 'helpers/network/networkAction';

import {
  GET_WORKSPACES,
  GET_WORKSPACE_BY_ID,
  DELETE_WORKSPACE,
  UPDATE_WORKSPACE,
  CREATE_WORKSPACE,
  GET_WORKSPACE_USERS,
  ADD_WORKSPACE_USER,
  DELETE_WORKSPACE_USER,
  SET_WORKSPACE_TO_DEFAULT,
} from 'constants/workspaces';

import * as Api from 'api';

export const getWorkspaces = () => async dispatch => networkAction(
  dispatch,
  GET_WORKSPACES,
  Api.getWorkspaces,
  [],
);

export const getWorkspaceById = id => async dispatch => networkAction(
  dispatch,
  GET_WORKSPACE_BY_ID,
  Api.getWorkspaceById,
  [id],
);

export const deleteWorkspace = id => async dispatch => networkAction(
  dispatch,
  DELETE_WORKSPACE,
  Api.deleteWorkspace,
  [id],
);

export const createWorkspace = body => async dispatch => networkAction(
  dispatch,
  CREATE_WORKSPACE,
  Api.createWorkspace,
  [body],
);

export const updateWorkspace = (id, body) => async dispatch => networkAction(
  dispatch,
  UPDATE_WORKSPACE,
  Api.updateWorkspace,
  [id, body],
);

export const setWorkspaceToDefault = (id, body) => async dispatch => networkAction(
  dispatch,
  SET_WORKSPACE_TO_DEFAULT,
  Api.setWorkspaceToDefault,
  [id, body],
);

export const getWorkspaceUsers = workspaceID => async dispatch => networkAction(
  dispatch,
  GET_WORKSPACE_USERS,
  Api.getWorkspaceUsers,
  [workspaceID],
);

export const addWorkspaceUser = (workspaceID, uuid) => async dispatch => networkAction(
  dispatch,
  ADD_WORKSPACE_USER,
  Api.addWorkspaceUser,
  [workspaceID, uuid],
);

export const deleteWorkspaceUser = (workspaceID, uuid) => async dispatch => networkAction(
  dispatch,
  DELETE_WORKSPACE_USER,
  Api.deleteWorkspaceUser,
  [workspaceID, uuid],
);
