import Train from '@material-ui/icons/Train';
import LocalConvenienceStore from '@material-ui/icons/LocalConvenienceStore';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import LocalShipping from '@material-ui/icons/LocalShipping';

export const icons = {
  Warehouse: LocalConvenienceStore,
  consignee: ArrowDownward,
  shipper: ArrowUpward,
  Railyard: Train,
  'Drop Yard': LocalShipping,
};
