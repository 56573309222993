import blue from "@material-ui/core/colors/blue";

export default (theme) => ({
  content: {
    position: "relative",
    borderRadius: 4,
  },
  icon: {
    color: blue[500],
    fontSize: 50,
  },
  paper: {
    padding: theme.spacing(2),
  },
  button: {
    textTransform: "none",
    color: "white",
    background: "#2196f3",
  },
});
