import {
  GET,
  PUT,
  DELETE,
  POST,
} from 'constants/methods';

import { REACT_APP_API_PREFIX } from 'config/env';

const service = 'service';
const workspaces = `${REACT_APP_API_PREFIX}/${service}/workspaces`;

export function getWorkspaces() {
  const url = `${workspaces}`;
  return {
    method: GET,
    url,
  };
}

export function getWorkspaceById(id) {
  const url = `${workspaces}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function setWorkspaceToDefault(id, body) {
  const url = `${workspaces}/${id}/default`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function updateWorkspace(id, body) {
  const url = `${workspaces}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteWorkspace(id) {
  const url = `${workspaces}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createWorkspace(body) {
  const url = `${workspaces}`;
  return {
    method: POST,
    url,
    body,
  };
}

export function getWorkspaceUsers(workspaceID) {
  const url = `${workspaces}/${workspaceID}/users`;
  return {
    method: GET,
    url,
  };
}

export function addWorkspaceUser(workspaceID, uuid) {
  const url = `${workspaces}/${workspaceID}/users`;
  return {
    method: POST,
    url,
    body: {
      userUID: uuid,
    },
  };
}

export function deleteWorkspaceUser(workspaceID, uuid) {
  const url = `${workspaces}/${workspaceID}/users`;
  return {
    method: DELETE,
    url,
    body: {
      userUID: uuid,
    },
  };
}
