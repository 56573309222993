import * as colors from '@material-ui/core/colors';
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const ColoredTextTypography = withStyles({
  root: {
    color: props => {
      const shade = props.shade || 500;
      if (!props.color) {
        return 'inherit';
      }
      return colors[props.color][shade];
    },
    fontSize: props => props.fontSize,
    lineHeight: 1
  }
})(Typography);

export default ColoredTextTypography;
